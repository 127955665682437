<template>
  <v-sheet class="main-content page-properties px-5">
    <page-header>
      <h2>{{ $t('privaatsuspoliitika') }}</h2>
    </page-header>
    <div class="tiptap tiptap-editor">
      <p>Lõhnakas OÜ jaoks on oluline Teie privaatsus ja järgida kõiki kehtivaid seadusi ja määrusi mis tahes isikuandmete kohta, mida võime Teie kohta koguda, kui külastate meie veebilehte <a href="hhttps://www.lohnaturundus.ee">lohnaturundus.ee</a>. Meie veebisaiti kasutades nõustute, et kogume ja kasutame Teie andmeid vastavalt käesolevale privaatsuspoliitikale.</p>
      <p>Käesoleva privaatsuspoliitikaga soovime Teile selgitada, milliseid andmeid me kogume, kuidas neid kasutame ja millised on Teie õigused oma andmete kaitsmiseks. Poliis rakendub ainult meie veebilehele ja selle külastajatele ning ei rakendu andmetele, mida kogutakse muude kanalite kaudu.</p>
      <p>Teie andmete töötleja on Lõhnakas OÜ </p>

      <p>e-post: info@lohnakas.ee </p>

      <p>Kogume ja töötleme teavet kooskõlas Euroopa Liidu üldise andmekaitse määrusega (GDPR), mis jõustus 25.05.2018.</p>
      <h2>Andmete kogumine ja kasutamine</h2>
      <h3>Milliseid andmeid me kogume</h3>
      <p>Kogutav teave hõlmab nii teavet, mida Te meile teadlikult ja aktiivselt edastate meie teenuste või kampaaniate kasutamisel või nendes osalemisel, kui ka kogu teavet, mille Teie seadmed saadavad meie toodetele ja teenustele juurde pääsemisel.</p>
      <p>Meie poolt kogutavad andmed sisaldavad endas teavet, mida olete meie teenuste või kampaaniate kasutamisel meile teadlikult jaganud ning teavet, mida Teie seadmed meile automaatselt edastavad.</p>
      <p>Teie isiklikud andmed võivad jõuda meieni järgmistel viisidel:</p>
      <ul>
        <li>Kui teete tellimuse <a href="https://www.lohnaturundus.ee">lohnaturundus.ee</a> e-poes</li>
        <li>Kui registreerite end <a href="https://www.lohnaturundus.ee">lohnaturundus.ee</a> e-poe kasutajaks</li>
        <li>Kui kontakteerute meiega telefoni, e-posti või online vestluse kaudu</li>
        <li>Kui ostate toote järelmaksuga ja sõlmite liisingulepingu</li>
        <li>Kui sirvite veebilehte <a href="https://www.lohnaturundus.eee">lohnaturundus.ee</a></li>
        <li>Kui esitate isiklikke andmeid teise isiku kohta, kes kauba vastu võtab</li>
        <li>Kui teete makseid kasutades e-panka või muud elektroonilist maksemeetodit</li>
        <li>Kui Te kommenteerite, laigite või jagate mõnda postitust meie sotsiaalmeedia kontol</li>
        <li>Kui liitute meie uudiskirjaga</li>
        <li>Kui kommenteerite meie veebilehel olevaid postitusi</li>
      </ul>
      <h3>Kogutavad andmetüübid</h3>
      <h4>Isikuandmed</h4>
      <p>Küsime Teilt isiklikku informatsiooni ainult siis, kui see on vajalik Teile teenuse osutamiseks. Võttes meiega ühendust või külastades meie veebilehte, on võimalik, et jagate meiega järgnevat teavet:</p>
      <ul><li>Ees- ja perekonnanimi</li><li>E-posti aadress</li><li>Telefoninumber</li></ul>
      <h4>Logifailid</h4>
      <p>Meie veebileht <a href="https://www.lohnaturundus.ee">lohnaturundus.ee</a> salvestab oma külastajate kohta logifaile. Kogutava teabe hulka võib kuuluda:</p>
      <ul>
        <li>Kasutatava seadme tüüp</li>
        <li>Interneti-protokolli (IP) aadress</li>
        <li>brauseri tüüp</li>
        <li>Internetiteenuse pakkuja (ISP)</li>
        <li>Veebilehe külastamise kuupäev ja kellaaeg</li>
        <li>Sisenemis- ja väljumisleht veebilehel tehtavate klikkide arv</li>
      </ul>
      <p>Logifailides olevat informatsiooni kasutatakse trendide analüüsimiseks, veebilehe administreerimiseks, lehe külastajate tegevuste jälgimiseks ning demograafilise info kogumiseks. Saadud teabe ei ole mõeldud, ega võimaldagi isikut identifitseerida.</p>
      <h4>Küpsised</h4>
      <p>Nagu enamus veebilehti, kasutab ka <a href="https://www.lohnaturundus.ee">lohnaturundus.ee</a> "küpsiseid". Küpsis on sisuliselt tekstifail, mis salvestatakse veebisaidi külastamisel kasutaja seadmesse. Küpsiste abil määratakse ja salvestatakse eelistused, regionaalsed sätted, teenuste kasutamise valikud jpm.</p>
      <p>Soovi korral võite brauseri seadistuses ära keelata küpsiste kasutamise, kuid sel juhul on võimalik, et Teile pakutav teenus on häiritud.</p>
      <p>Küpsised jaotuvad püsi- ja sessiooniküpsisteks. Püsiküpsised jäävad Teie personaalarvutisse või mobiilseadmesse ka peale seda, kui olete sulgenud brauseri või arvuti. Sessiooniküpsised kustutatakse aga kohe pärast brauseri sulgemist.</p>
      <p>Küpsised jagunevad oma eesmärgi järgi:</p>
      <p><b>Vajalikud küpsised.</b> Sessiooniküpsised, mille eesmärgiks on võimaldada Teile meie veebisaidil pakutavate teenuste kasutamist. Need küpsised aitavad kasutajaid autentida ja vältida kasutajakontode pahatahtlikku kasutamist. Ilma nende küpsisteta ei saa me Teile soovitud teenuseid pakkuda.</p>
      <p><b>Teavitusküpsised.</b> Need küpsised tuvastavad, kas kasutajad on veebisaidil küpsiste kasutamist aktsepteerinud.</p>
      <p><b>Funktsionaalsuse küpsised.</b> Need küpsised võimaldavad Teie brauseril meeles pidada valikuid, mida teete veebisaidi kasutamisel, näiteks Teie sisselogimisandmete või keele-eelistuste salvestamine. Nende küpsiste eesmärk on pakkuda Teile personaalsemat kogemust ja vältida eelistuste uuesti sisestamist.</p>
      <h3>Kuidas me andmeid kasutame</h3>
      <p>Kogutud informatsiooni võime kasutada erinevatel eesmärkidel, sealhulgas:</p>
      <ul>
        <li>Meie veebilehe hooldamiseks, haldamiseks, arendamiseks, personaliseerimiseks ja laiendamiseks</li>
        <li>Statistika kogumiseks, et saaksime pakkuda paremaid teenuseid ja/või pakkumisi ning mõista ja analüüsida meie veebilehe külastajate käitumist</li>
        <li>Teie tuvastamiseks telefoni, sõnumi või e-posti teel</li>
        <li>Teile informatsiooni saatmisel ja meie teenuste kohta tagasiside palumisel</li>
        <li>Pettuste vältimiseks ja meie veebilehe kaitsmiseks</li>
        <li>Võimaldamaks meil Teiega ühenduses olla sotsiaalmeedia konto kaudu</li>
        <li>Vestlusajaloo lugemise võimaldamiseks, et kiirendada asjakohase nõu andmist</li>
        <li>Eesmärgiga parandada meie teenuse kvaliteeti ja pakkuda tõhusamaid sirvimislahendusi</li>
        <li>Et arendada uusi uusi tooteid, teenuseid või parandada nende funktsionaalsust</li>
        <li>Kauba saatmiseks/kättetoimetamiseks, tellimuste töötlemiseks, arvete vormistamiseks, pangalingi kaudu tellimiseks ja Teie tellimuse kinnitamiseks või raha tagastamiseks</li>
        <li>Teie eelnevaid tellimusi arvesse võttes Teile huvi pakkuda võivate toodete ja teenuste pakkumine</li>
        <li>Meievaheliste interaktsioonide lihtsustamiseks ja kiirendamiseks, kui võtate meiega ühendust</li>
        <li>Muudel eesmärkidel. Näiteks andmete analüüsimiseks, trendide uurimiseks, meie reklaamikampaaniate tõhususe hindamiseks.</li>
      </ul>
      <h3>Millistel juhtudel on võimalik, et jagame Teie andmeid</h3>
      <ul>
        <li>
          Võime jagada või edastada Teie isikuandmeid seoses meie ettevõtte omanike vahetuse, ühinemise, varade müügi, finantseerimise või omandamise käigus. Teavitame Teid Teie isikuandmete edastamisest ja sellest, kui neile hakkavad kehtima mõne teise privaatsuspoliitika sätted.
        </li>
        <li>
          Võime jagada Teie andmeid oma partnerettevõtetega, nõudes neilt käesoleva privaatsuspoliitika järgimist. Partnerettevõtete hulka kuuluvad meie ema- ja tütarettevõtted, ühisettevõtte partnerid või ettevõtted, mis kuuluvad meile.
        </li>
        <li>Võime jagada Teie andmeid oma äripartneritega, et pakkuda Teile teatud tooteid, teenuseid või kampaaniaid.</li>
        <li>Võime Teie nõusolekul avaldada Teie isikuandmeid mis tahes muul eesmärgil.</li>
        <li>
          Oleme kohustatud Teie isikuandmed avaldama, kui seda nõuab seadus. Heas usus võime avaldada ka Teie andmeid, et järgida seadusest tulenevat kohustust, kaitsta meie õigusi või omandit, ennetada või tuvastada võimalikke meie teenuse kasutamisega seonduvaid õigusrikkumisi ja tagada teenuse kasutajate turvalisus.
        </li>
      </ul>
      <h3>Teie isikuandmete säilitamine</h3>
      <p>Ettevõte säilitab Teie isikuandmeid ainult seni, kuni see on vajalik käesolevas privaatsuspoliitikas sätestatud eesmärkidel. Säilitame ja kasutame Teie isikuandmeid ulatuses, mis on vajalik meie juriidiliste kohustuste täitmiseks (näiteks kui peame Teie andmeid säilitama kehtivate seaduste järgimiseks), vaidluste lahendamiseks ning meie juriidiliste lepingute ja eeskirjade jõustamiseks.</p>
      <p>Ettevõte säilitab logifaile ka siseanalüüsi eesmärgil. Logifaile säilitatakse tavaliselt lühemat aega, välja arvatud juhul, kui neid andmeid kasutatakse turvalisuse tugevdamiseks või meie teenuse funktsionaalsuse parandamiseks või kui me oleme seadusega kohustatud neid andmeid pikemaks ajaks säilitama.</p>
      <h3>Laste andmete töötlemine</h3>
      <p>Ükski meie toode või teenus ei ole suunatud alla 13-aastastele lastele ja me ei kogu teadlikult alla 13-aastaste laste andmeid. Kahtluse korral, et töötleme alla 13-aastase isiku andmeid, eemaldame selle isiku kõigist meie andmebaasidest.</p>
      <h2>Teie õigused oma andmete kaitsmiseks</h2>
      <p>Teil on alati õigus oma andmeid meiega mitte jagada, teadmisega, et see võib mõjutada Teie kogemust meie veebisaidil. Kui otsustate oma andmeid meiega mitte jagada, ei kohtle me Teid seepärast halvemini. Kui edastate meile isiklikku teavet, mõistate, et kogume, hoiame, kasutame ja avaldame seda vastavalt käesolevale privaatsuspoliitikale. Teil on õigus nõuda Teie kohta kogutavate andmete üksikasju.</p>
      <p>Saades Teie kohta isiklikku teavet kolmandalt osapoolelt, kaitseme seda vastavalt käesolevale privaatsuspoliitikale ja Eesti Vabariigi seadusele. Kui jagate ise kolmanda osapoole andmeid, kinnitate, et Teil on selleks ka õigus ja luba.</p>
      <p>Isegi kui olete varem andnud nõusoleku meiega oma isiklikke andmeid turunduse eesmärkidel jagada, jääb Teile õigus igal ajal oma meelt muuta. Teil on alati õigus loobuda meiega suhtlemisest ja nõuda, et eemaldaksime Teie andmed meie andmebaasidest. Aeg-ajalt võime ka küsida Teie kohta teavet Teie isiku kinnitamiseks. Kui leiate, et andmed, mida Teie kohta kogunud oleme, on ebatäpsed, aegunud, puudulikud, ebaolulised või eksitavad, palun võtke meiega ühendust e-posti teel: info@lohnakas.ee. Teeme kõik meist oleneva, et parandada ebatäpset, puudulikku, eksitavat või aegunud teavet.</p>
      <p>Kui arvate, et oleme rikkunud asjakohast andmekaitseseadust ja soovite esitada kaebuse, palun võtke meiega ühendust, kasutades käesolevast dokumendist leitavaid kontaktandmeid ja edastage meile kõik rikkumise kohta käivad üksikasjad. Uurime Teie kaebust viivitamata ja vastame teile kirjalikult, esitades uurimise tulemused ja toome välja sammud, mida Teie kaebuse lahendamiseks ette võtame. Samuti on Teil õigus pöörduda reguleeriva asutuse või andmekaitseasutuse poole.</p>
      <h2>GDPR andmekaitseõigused</h2>
      <p>Soovime olla veendunud, et olete teadlik kõigist oma andmekaitseõigustest. Igal lehe kasutajal on õigus järgmistele tingimustele:</p>
      <ul>
        <li>Juurdepääsuõigus - teil on õigus nõuda oma isikuandmete koopiaid.</li>
        <li>Õigus andmete parandustele - teil on õigus nõuda, et parandaksime Teie arvates ebatäpsed andmed. Samuti on teil õigus nõuda, et täiendaksime andmeid, mis on Teie arvates puudulikud.</li>
        <li>Kustutamisõigus - teatud tingimustel on teil õigus nõuda Teie isikuandmete kustutamist.</li>
        <li>Andmete töötlemise piiramise õigus - teatud tingimustel on teil õigus nõuda Teie isikuandmete kasutamise piiramist.</li>
        <li>Õigus vaidlustada isikuandmete kasutamine - teatud tingimustel on teil õigus vaidlustada Teie isikuandmete kasutamine.</li>
        <li>Õigus andmete teisaldatavusele - teatud tingimustel on teil õigus nõuda, et me edastaksime kogutud andmed kolmandale osapoolele või otse teile.</li>
      </ul>
      <h2>Teie andmete turvalisus</h2>
      <p>Teie isikuandmete turvalisus on meie jaoks oluline, kuid pidage meeles, et ükski Interneti-teel info vahetamise viis ega elektrooniline salvestusmeetod pole 100% turvaline. Kuigi anname endast kõik, et kaitsta Teie andmeid, ei saa me tagada nende täielikku turvalisust.</p>
      <p>Lõhnakas OÜ võtab ette kõik mõistlikult vajalikud meetmed, et tagada Teie andmete töötlemine turvaliselt ja kooskõlas käesoleva privaatsuspoliitika ning Eesti Vabariigi seadusega. Teie andmeid ei edastata ühelegi organisatsioonile ega riigile, välja arvatud juhul, kui selleks on mõjuv põhjus ja on tagatud Teie andmete turvalisus.</p>
      <h2>Viitamine teistele veebisaitidele</h2>
      <p>Meie veebisaidilt võite leida linke teistele veebilehtedele, mis ei kuulu meile. Kui vajutate kolmanda osapoole lingil, suunatakse teid selle kolmanda osapoole saidile. Soovitame tungivalt tutvuda iga külastatava saidi privaatsuseeskirjaga.</p>
      <p>Me ei oma mingit kontrolli kolmandate osapoolte saitide üle ega võta vastutust nende sisu, privaatsuspoliitika, pakutavate teenuste või muu eest.</p>
      <h2>Privaatsuspoliitika uuendused</h2>
      <p>Vajadusel on meil õigus teha käesolevale privaatsuspoliitika dokumendile muudatusi. Privaatsuspoliitika uuenemise järel muudame me selle allosas paiknevat dokumendi värskendamise kuupäeva. Kui külastate ja kasutate meie veebilehte ka pärast privaatsuspoliitika uuendamist, käsitleme me seda kui Teie vaikivat nõusolekut muudatustega.</p><br>
      <p>Viimati muudetud 14/06/2024</p><br>

    </div>
  </v-sheet>
</template>

<script>
import PageHeader from "@/components/PageHeader";
export default {
  name: "Privacy",
  components: {PageHeader}
}
</script>

<style scoped>

</style>