<!-- Sisse logitud kasutaja profiil -->
<template>
  <v-menu min-width="200px" rounded location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn class="d-flex" icon variant="plain" v-bind="props">
        <v-icon size="40">mdi-account-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <div class="mx-auto text-center">
          <div class="d-flex align-center ga-3">
            <v-avatar color="eraklient">
              <v-icon>mdi-account-circle</v-icon>
            </v-avatar>
            <h3>{{ user.email }}</h3>
          </div>
          <v-divider class="my-3"></v-divider>
          <v-btn color="error" rounded @click="Logout">Log out</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {toast} from "vue3-toastify";

export default {
  name: "UserProfile",
  data: () => ({
    user: []
  }),
  methods: {
    fetchAUser(name) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/users/${name}`)
          .then((response) => response.json())
          .then((data) => {
            this.user = data;
          })
          .catch((err) => console.log(err.message));
    },
    Logout() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/auth/logout`, {
        credentials: 'include',
      })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            console.log('jwt removed');
            this.$store.commit('SET_LOGIN_STATUS', false);
            toast.success("Logged out");
          })
          .catch((e) => {
            console.log(e);
            console.log("error logout");
          });
    }
  },
  mounted() {
    this.fetchAUser(this.$store.getters.getUser);
  }
}
</script>

<style scoped>

</style>