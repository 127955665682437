<template>
  <v-breadcrumbs class="crumbs-list" :items="breadcrumbs" divider="/">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">{{ item.name }}</v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        if (to.name === 'Product' || to.name === 'EditProduct') {
          this.$store.dispatch('fetchProduct', to.params.id);
        }
        else if (to.name === 'Postitus') {
          this.$store.dispatch('fetchPost', to.params.id);
        }
      },
    },
  },
  computed: {
    product() {
      return this.$store.getters.getProduct;
    },
    post() {
      return this.$store.getters.getPost;
    },
    categories() {
      return this.$store.getters.getCategories;
    },
    breadcrumbs() {
      let breadcrumbs = [];
      this.$route.matched.forEach((route, index) => {
        let name;
        let path = route.path;
        if (route.meta.dynamicName) {
          if (route.name === 'ToodeteKategooriad') {
            let category = this.$route.params.category;
            let value;
            for (let obj of this.categories) {
              if (obj.name.toLowerCase() === category) {
                value = this.$i18n.locale === 'en' ? obj.name_en : this.$i18n.locale === 'ru' ? obj.name_ru : obj.name;
                break;
              }
            }
            name = value;
            path = path.replace(':category', this.$route.params.category);
          }
          else if (route.name === 'Product' || route.name === 'EditProduct') {
            name = this.product ? (this.$i18n.locale === 'en' ? this.product.header_en : this.$i18n.locale === 'ru' ? this.product.header_ru : this.product.header) : '';
            path = path.replace(':id', this.$route.params.id);
          }
          else if (route.name === 'Postitus') {
            name = this.post ? ((this.$i18n.locale === 'en' && this.post.header_en !== null) ? this.post.header_en : (this.$i18n.locale === 'ru' && this.post.header_ru !== null) ? this.post.header_ru : this.post.header) : '';
            if (name.length > 40) {
              name = name.substring(0, 40) + '...';
            }
            path = path.replace(':id', this.$route.params.id);
          }
        }
        else name = this.$t(route.name.toLowerCase());

        //name = name.charAt(0).toUpperCase() + name.slice(1);
        if (!breadcrumbs.some(breadcrumb => breadcrumb.name === name)) {
          breadcrumbs.push({ path: path, name: name, disabled: false });
        }
      });
      if (breadcrumbs.length > 0) {
        breadcrumbs[breadcrumbs.length - 1].disabled = true; // disable the last item
      }
      return [{ path: '/', name: this.$t('avaleht') }, ...breadcrumbs];
    },
  },

}
</script>

<!-- https://codepen.io/mrtnvh/pen/DwdjXW -->
<style lang="scss">
.crumbs-list {
  padding: 1em;
  white-space:nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  font-size: 15px;
  line-height: 14px;
  list-style: none;
  width: 100%;
  margin: 0;
  justify-content: right;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none;
  }
  li {
    &:after {
      padding: 0 0.25em 0 0.5em;
      color: #999;
    }
    &.last:before, &.last:after {
      content: '';
    }
  }
}

@media (max-width: 1000px) {
  .crumbs-list {
    justify-content: center;
  }
}
</style>