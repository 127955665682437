<!-- Avalehe tagasiside lisamise vaheleht -->
<template>
  <v-sheet class="page-properties pa-4">
    <page-header>
      <h1>Uus tagasiside</h1>
    </page-header>
    <v-form ref="form">
      <v-textarea
          v-model=feedback.body
          label="Sisu"
          :rules="rules"
          required
          variant="outlined"
      ></v-textarea>
      <v-textarea
          v-model=feedback.body_en
          label="Sisu(en)"
          required
          variant="outlined"
      ></v-textarea>
      <v-textarea
          v-model=feedback.body_ru
          label="Sisu(ru)"
          required
          variant="outlined"
      ></v-textarea>
    </v-form>
    <v-btn color="success" @click="addFeedback" block class="mt-2">Lisa</v-btn>
  </v-sheet>
</template>

<script>
import PageHeader from "@/components/PageHeader";
export default {
  name: "AddFeedback",
  components: {PageHeader},
  data() {
    return {
      valid: false,
      feedback: {
        body: "",
        body_en: null,
        body_ru: null
      },
      rules: [
        value => {
          if (value) return true
          return 'Ei saa olla tühi.'
        },
      ]
    };
  },
  methods: {
    async addFeedback() {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        var data = {
          body: this.feedback.body,
          body_en: this.feedback.body_en,
          body_ru: this.feedback.body_ru
        };
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/feedbacks`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
            .then((response) => {
              console.log(response.data);
              this.$router.push("/");
            })
            .catch((e) => {
              console.log(e);
              console.log("error");
            });
      }
    },
  },
}
</script>

<style scoped>

</style>