<!-- Uudise lisamise vaheleht -->
<template>
  <page-header class="px-10">
    <h1 >Uus uudis</h1>
  </page-header>
  <v-sheet class="post-body d-flex justify-center pa-10 ga-10">
      <div class="products">
        <v-form ref="form">
          <v-text-field
              v-model=post.image
              label="Pilt"
              variant="outlined"
          ></v-text-field>
          <v-tabs v-model="tab" bg-color="eraklient">
            <v-tab value="one" @click="changeLocale('et')">EST</v-tab>
            <v-tab value="two" @click="changeLocale('en')">ENG</v-tab>
            <v-tab value="three" @click="changeLocale('ru')">RUS</v-tab>
          </v-tabs>
          <v-window class="py-5" v-model="tab">
            <v-window-item value="one">
              <v-text-field
                  v-model=post.header
                  label="Pealkiri"
                  :rules="rules"
                  variant="outlined"
              ></v-text-field>
              <tiptap v-model="post.body"/>
            </v-window-item>
            <v-window-item value="two">
              <v-text-field
                  v-model=post.header_en
                  label="Pealkiri(en)"
                  variant="outlined"
              ></v-text-field>
              <tiptap v-model="post.body_en"/>
            </v-window-item>
            <v-window-item value="three">
              <v-text-field
                  v-model=post.header_ru
                  label="Pealkiri"
                  variant="outlined"
              ></v-text-field>
              <tiptap v-model="post.body_ru"/>
            </v-window-item>
          </v-window>
        </v-form>
        <v-btn color="success" @click="addPost" class="mt-2">Lisa</v-btn>
      </div>
      <div class="products">
        <v-sheet class="post-container pa-16">
          <v-list-item-title class="text-h4 font-weight-bold py-6 text-center">{{ getHeader(post) }}</v-list-item-title>
          <img v-if="post.image" alt="" :src="post.image" />
          <img v-else alt="lõhnakas logo" src="../../assets/images/lohnakas.webp" />
          <div class="tiptap tiptap-editor" v-html=getBody(post)></div>
        </v-sheet>
      </div>
  </v-sheet>
</template>

<script>
import Tiptap from '@/components/Tiptap.vue'
import PageHeader from "@/components/PageHeader";
export default {
  name: "AddPost",
  components: {PageHeader, Tiptap},
  data() {
    return {
      valid: false,
      tab: null,
      post: {
        image: "",
        header: "",
        header_en: null,
        header_ru: null,
        body: "",
        body_en: null,
        body_ru: null,
        date: "",
      },
      rules: [
        value => {
          if (value) return true
          return 'Ei saa olla tühi.'
        },
      ]
    };
  },
  methods: {
    async addPost() {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        var data = {
          image: this.post.image,
          header: this.post.header,
          header_en: this.post.header_en,
          header_ru: this.post.header_ru,
          body: this.post.body,
          body_en: this.post.body_en,
          body_ru: this.post.body_ru,
        };
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/posts`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
            .then((response) => {
              console.log(response.data);
              location.assign("/uudised");
            })
            .catch((e) => {
              console.log(e);
              console.log("error");
            });
      }
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    },
    changeLocale(value) {
      this.$i18n.locale = value;
    }
  },
}
</script>

<style scoped>
.v-list-item-title {
  white-space: break-spaces;
  line-height: 1em;
}

.post-container {
  border-width: thin;
}
</style>