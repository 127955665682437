<template>
  <div class="page-properties pt-10">
    <v-sheet class="rounded-lg text-center pa-5 mx-auto" elevation="3" max-width="500">
      <form>
        <h3 class="pa-5">Login</h3>
        <v-text-field type="text" label="username" variant="outlined" v-model="username"></v-text-field>
        <v-text-field type="password" label="password" variant="outlined" v-model="password"></v-text-field>
        <v-btn color="eraklient" @click="LogIn" class="center">LogIn</v-btn>
      </form>
    </v-sheet>
  </div>
</template>

<script>
import {toast} from "vue3-toastify";
import PageHeader from "@/components/PageHeader";

export default {
  name: "LogIn",
  components: {PageHeader},
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    LogIn() {
      var data = {
        email: this.username,
        password: this.password
      };
      fetch(`${process.env.VUE_APP_SERVER_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Wrong credentials');
            }
            return response.json();
          })
          .then((data) => {
            console.log(data);
            this.$store.commit('SET_LOGIN_STATUS', true);
            this.$store.commit('setUser', this.username);
            this.$store.commit('SET_TOAST_MESSAGE', "Logged in");
            location.assign("/");
          })
          .catch((e) => {
            console.log(e);
            toast.error("Wrong credentials");
          });
    }
  }
}
</script>

<style scoped>

</style>