<!-- Külje pealt avanev ostukorv -->
<template>
  <div class="clickable" @click.stop="drawer = !drawer">
    <v-badge v-if="cartItemCount > 0" color="red" :content="cartItemCount">
      <v-icon class="bi bi-bag"></v-icon>
    </v-badge>
  </div>
  <v-layout class="v-overlay-scroll-blocked">
    <v-navigation-drawer v-model="drawer" width="500" location="right" temporary touchless>
      <v-toolbar color="white">
        <v-btn aria-label="close" icon="mdi-close" @click="drawer = false"></v-btn>
        <v-toolbar-title>{{ $t('ostukorv') }}</v-toolbar-title>
      </v-toolbar>
      <v-list class="pa-6" aria-label="Product List" role="listbox">
        <v-list-item class="pa-0" v-for="(item, productId) in cart" :key="productId" role="option" aria-selected="false">
          <div class="d-flex w-100 my-6 align-center cart-content">
            <a class="d-flex align-center flex-1-1 mr-6" :href="'/tooted/' + getLinkCat(item.product) + '/' + productId">
              <div class="d-flex align-center justify-center mr-5 cart-img">
                <v-img v-if="item.product.image" width="80" height="80" cover :src="item.product.image" draggable="false"></v-img>
                <img class="cart-icon" v-else src="../assets/images/lohnakas.webp" alt="Lohnakas logo" draggable="false"/>
              </div>
              <div class="d-flex flex-column w-100">
                <span class="font-weight-bold text-left"> {{ getHeader(item.product) }} </span>
              </div>
            </a>
            <div class="d-flex flex-column">
              <div class="flex-column d-flex text-center">
                <div class="product-price font-weight-bold">
                  <span class="new-price" v-if="item.product.new_price"> {{ item.product.new_price }}€/{{$t('tk')}}</span>
                  <span class="old-price" v-if="item.product.new_price"> {{ item.product.price }}€</span>
                  <span v-if="!item.product.new_price"> {{ item.product.price }}€/{{$t('tk')}}</span>
                </div>
              </div>
              <div class="d-flex align-center">
                <v-btn aria-label="Decrease Quantity" color="eraklient" @click="decreaseQuantity(productId)" icon="mdi-minus" size="30px"><v-icon size="20px"></v-icon></v-btn>
                <div class="item-qty">
                  <input :title="$t('valige_kogus')" role="textbox" :aria-label="$t('quantity_input')" v-model="item.quantity" variant="outlined"/>
                </div>
                <v-btn aria-label="Increase Quantity" color="eraklient" @click="increaseQuantity(productId)" icon="mdi-plus" size="30px"><v-icon size="20px"></v-icon></v-btn>
              </div>
            </div>
          </div>
        </v-list-item>
        <div v-if="Object.keys(cart).length > 0">
          <v-divider class="mx-3"></v-divider>
          <div class="order-items my-6">
            <div class="text-uppercase">{{ $t('kokku') }}</div>
            <div class="font-weight-bold"> {{ ($store.getters.cartTotal).toFixed(2) }}€ </div>
          </div>
          <div class="d-flex flex-column">
            <v-btn aria-label="toCart" color="eraklient" to="/tooted/ostukorv" @click="drawer = false">{{ $t('suundu_ostukorvi') }}</v-btn>
            <v-dialog v-model="empty_cart" width="auto" transition="none">
              <v-card>
                <v-toolbar color="white" :title="$t('kinnitus')">
                  <v-btn aria-label="confirmation" icon="mdi-close" @click="empty_cart = false"></v-btn>
                </v-toolbar>
                <v-card-text>
                  <div class="text-h5 justify-center text-center">{{$t('eemalda_conf')}}</div>
                </v-card-text>
                <v-divider></v-divider>
                <div class="pa-4">
                  <v-btn aria-label="deleteCart" class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="deleteCart" @click="showToastSuccess($t('tuhjendatud'))">{{$t('jah')}}</v-btn>
                  <v-btn aria-label="cancel" class="text-none font-weight-bold" min-width="92" rounded @click="empty_cart = false">{{$t('ei')}}</v-btn>
                </div>
              </v-card>
            </v-dialog>
            <v-btn aria-label="deleteCart" class="my-2" color="error" aria-haspopup="true" :aria-expanded="empty_cart" @click="empty_cart = true">{{$t('tühjenda_korv')}}</v-btn>
          </div>
        </div>
        <p role="option" v-if="Object.keys(cart).length === 0">{{ $t('ostukorv_tuhi') }}</p>
      </v-list>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
import {toast} from "vue3-toastify";

export default {
  name: "ShoppingCart",
  data() {
    return {
      drawer: false,
      empty_cart: false
    };
  },
  computed: {
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
    cart() {
      return this.$store.state.cart;
    }
  },
  methods: {
    increaseQuantity(productId) {
      this.$store.commit('increaseQuantity', productId);
    },
    decreaseQuantity(productId) {
      this.$store.commit('decreaseQuantity', productId);
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getLinkCat(content) {
      return this.$store.getters.getLinkCat(content);
    },
    deleteCart() {
      this.drawer = false;
      this.$store.commit('deleteCart');
    },
    showToastSuccess(value) {
      toast.success(value, {
        autoClose: 3000
      })
    }
  }
}
</script>

<style scoped>
.v-badge {
  display: flex;
}

@media (max-width: 400px) {
  .cart-img {
    display: none !important;
  }
}
</style>