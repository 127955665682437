<template>
  <div class="offer-link clickable" @click="form = true">
    <div>{{$t('pakkumine')}}</div>
    <v-icon>mdi-chevron-right</v-icon>
  </div>
  <v-dialog v-model="form" max-width="500" :fullscreen="xs" transition="none">
    <v-card>
      <v-toolbar color="white" :title="getTitle(service)">
        <v-btn icon="mdi-close" @click="form = false; handleReset()"></v-btn>
      </v-toolbar>
      <form class="pa-5" @submit.prevent="submit" id="request-form">
        <v-text-field
            variant="outlined"
            v-model="name.value.value"
            :error-messages="name.errorMessage.value"
            :label="$t('nimi')"
        ></v-text-field>
        <v-text-field
            variant="outlined"
            v-model="ettevote.value.value"
            :label="$t('ettev')"
        ></v-text-field>
        <v-text-field
            variant="outlined"
            v-model="phone.value.value"
            :error-messages="phone.errorMessage.value"
            :label="$t('tel')"
        ></v-text-field>
        <v-text-field
            variant="outlined"
            v-model="email.value.value"
            :error-messages="email.errorMessage.value"
            :label="$t('email')"
        ></v-text-field>
        <v-select
            variant="outlined"
            menu-icon="mdi-chevron-down"
            v-model="select_city.value.value"
            :items="cities"
            :error-messages="select_city.errorMessage.value"
            :label="$t('toimumiskoht')"
        ></v-select>
        <v-textarea
            variant="outlined"
            v-model="message.value.value"
            :label="$t('sonum_valik')"
        ></v-textarea>
        <input type="text" name="honeypot" id="honeypot" style="display: none;" v-model="honeypot.value.value">
        <v-btn color="eraklient" class="d-flex w-100" type="submit" value="Submit">{{ $t('saada') }}</v-btn>
      </form>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {useField, useForm} from "vee-validate";
import {ref, computed} from "vue";
import {toast} from "vue3-toastify";
import { useDisplay } from 'vuetify'
const { xs } = useDisplay()
const { t, locale } = useI18n()
const form = ref(false);
const props = defineProps(['service']);
import { useStore } from 'vuex'

const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    name (value) {
      if (value?.length > 1) return true
      return t('sisesta_nimi')
    },
    email (value) {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) return true
      return t('sisesta_email')
    },
    phone (value) {
      if (value?.length > 6 && /[0-9-]+/.test(value)) return true
      return t('sisesta_tel')
    },
    select_city (value) {
      if (value) return true
      return t('kohtumiskoht')
    },
  },
})
const name = useField('name')
const ettevote = useField('ettevote')
const phone = useField('phone')
const email = useField('email')
const select_city = useField('select_city')
const message = useField('message')
const honeypot = useField('honeypot')
const store = useStore()

const cities = computed(() => {
  return [
    t('stuudio'),
    t('Tartu'),
    t('Tallinn'),
    t('Pärnu'),
    t('Rakvere'),
    t('Valga'),
    t('Muu')
  ];
})

const items = computed(() => {
  return [
    t("eesti"),
    t("inglise"),
    t("vene"),
    t("ukraina"),
  ];
})

const submit = handleSubmit(async values => {
  if (honeypot.value.value) {
    toast.success(t('mess_succ'));
    handleReset();
    form.value = false;
    return
  }
  try {
    const newValues = {
      ...values,
      subject: "|TEENUS| " + props.service.title
    };
    const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newValues)
    });

    const data = await response.text();
    console.log(data);
    if (response.ok) {
      toast.success(t('offer_succ'));
      handleReset();
      form.value = false;
    }
    else {
      toast.error(t('offer_err'));
    }
  }
  catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
})

const getTitle = (content) => {
  return (locale.value === 'en' && content.title_en !== null) ? content.title_en : (locale.value === 'ru' && content.title_ru !== null) ? content.title_ru : content.title;
};
</script>

<style scoped>
.offer-link {
  display: inline-flex;
  align-items: center;
  color: #3b71f7;
  text-transform: uppercase;
  font-weight: 600;
}

.offer-link div:hover {
  text-decoration: underline;
}

.participants-input {
  width: 200px !important;
}

@media (max-width: 650px) {
  .offer-link {
    padding-bottom: 20px;
  }
}
</style>