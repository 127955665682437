<!-- Vaheleht "Kontakt" -->
<template>
  <div class="page-properties px-5">
    <PageHeader>
      <h1>{{ $t('kontakt') }}</h1>
    </PageHeader>
    <v-sheet class="d-flex mt-5 ga-8 contact-content">
      <v-sheet class="text-left d-flex flex-column w-100">
        <span class="text-h5 font-weight-bold mb-4 contact-header">{{ $t('ohuvitamiinid') }}</span>
        <div class="py-7 d-flex flex-column">
          <div class="d-flex align-center">
            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(90)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 5.5C21 14.0604 14.0604 21 5.5 21C5.11378 21 4.73086 20.9859 4.35172 20.9581C3.91662 20.9262 3.69906 20.9103 3.50103 20.7963C3.33701 20.7019 3.18146 20.5345 3.09925 20.364C3 20.1582 3 19.9181 3 19.438V16.6207C3 16.2169 3 16.015 3.06645 15.842C3.12515 15.6891 3.22049 15.553 3.3441 15.4456C3.48403 15.324 3.67376 15.255 4.05321 15.117L7.26005 13.9509C7.70153 13.7904 7.92227 13.7101 8.1317 13.7237C8.31637 13.7357 8.49408 13.7988 8.64506 13.9058C8.81628 14.0271 8.93713 14.2285 9.17882 14.6314L10 16C12.6499 14.7999 14.7981 12.6489 16 10L14.6314 9.17882C14.2285 8.93713 14.0271 8.81628 13.9058 8.64506C13.7988 8.49408 13.7357 8.31637 13.7237 8.1317C13.7101 7.92227 13.7904 7.70153 13.9509 7.26005L13.9509 7.26005L15.117 4.05321C15.255 3.67376 15.324 3.48403 15.4456 3.3441C15.553 3.22049 15.6891 3.12515 15.842 3.06645C16.015 3 16.2169 3 16.6207 3H19.438C19.9181 3 20.1582 3 20.364 3.09925C20.5345 3.18146 20.7019 3.33701 20.7963 3.50103C20.9103 3.69907 20.9262 3.91662 20.9581 4.35173C20.9859 4.73086 21 5.11378 21 5.5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            <span class="pl-3 contact-font"><a href="tel:+37253830335" draggable="false">+372 5383 0335</a></span>
          </div>
          <div class="d-flex align-center pt-3">
            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7" stroke="#000000" stroke-width="1.56" stroke-linecap="round" stroke-linejoin="round"></path> <rect x="3" y="5" width="18" height="14" rx="2" stroke="#000000" stroke-width="1.56" stroke-linecap="round"></rect> </g></svg>
            <span class="pl-3 contact-font"><a href="mailto:info@lohnakas.ee" draggable="false">info@lohnakas.ee</a></span>
          </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d282.4620942260714!2d26.72002961306153!3d58.37322832407397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eb372018a02c6d%3A0x72e3ed578e1b2c76!2sRiia%2015b%2C%2051010%20Tartu!5e0!3m2!1set!2see!4v1705335424252!5m2!1set!2see"
                style="border:0; border-radius: 8px;"
                allowfullscreen
                loading="lazy"
                class="w-100 h-100 d-flex"
                title="Google Maps"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
      </v-sheet>
      <v-sheet class="w-100">
        <span class="text-h5 font-weight-bold mb-4">{{ $t('uhendus') }}</span>
        <form class="mt-5" @submit.prevent="submit">
          <v-text-field
              variant="outlined"
              v-model="name.value.value"
              :error-messages="name.errorMessage.value"
              :label="$t('nimi')"
          ></v-text-field>
          <v-text-field
              variant="outlined"
              v-model="phone.value.value"
              :error-messages="phone.errorMessage.value"
              :label="$t('tel')"
          ></v-text-field>
          <v-text-field
              variant="outlined"
              v-model="email.value.value"
              :error-messages="email.errorMessage.value"
              :label="$t('email')"
          ></v-text-field>
          <v-textarea
              variant="outlined"
              v-model="message.value.value"
              :error-messages="message.errorMessage.value"
              :label="$t('sonum')"
          ></v-textarea>
          <input type="text" name="honeypot" id="honeypot" style="display: none;" v-model="honeypot.value.value">
          <v-btn color="eraklient" class="d-flex w-100" type="submit" value="Submit">{{ $t('saada') }}</v-btn>
        </form>
      </v-sheet>
    </v-sheet>
  </div>
</template>


<script setup>
import PageHeader from "@/components/PageHeader";
import {useField, useForm} from "vee-validate";
import {toast} from "vue3-toastify";
import {useI18n} from "vue-i18n";
const { t, locale } = useI18n()

const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    name (value) {
      if (value?.length > 1) return true
      return t('sisesta_nimi')
    },
    email (value) {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) return true
      return t('sisesta_email')
    },
    phone (value) {
      if (value?.length > 6 && /[0-9-]+/.test(value)) return true
      return t('sisesta_tel')
    },
    message (value) {
      if (value) return true
      return t('sisesta_sonum')
    }
  },
});
const name = useField('name')
const phone = useField('phone')
const email = useField('email')
const message = useField('message')
const honeypot = useField('honeypot')

const submit = handleSubmit(async values => {
  if (honeypot.value.value) {
    toast.success(t('mess_succ'));
    handleReset();
    return
  }
  try {
    const newValues = {
      ...values,
      subject: "Kontaktivormi kiri"
    };
    const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newValues)
    });

    const data = await response.text();
    console.log(data);
    if (response.ok) {
      toast.success(t('mess_succ'));
      handleReset();
    }
    else {
      toast.error(t('mess_err'));
    }
  }
  catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
})
</script>

<style scoped>
.contact-font {
  font-size: 1.2em;
}

@media (max-width: 820px) {
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
  iframe {
    height: 300px !important;
  }
  .contact-header {
    text-align: center;
  }
}

</style>