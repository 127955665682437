<template>
  <page-header class="px-10">
    <h1>Uus üritus</h1>
  </page-header>
  <v-sheet class="d-flex flex-column mx-auto pa-10">
    <v-form ref="form">
      <v-text-field
          v-model=post.image
          label="Pilt"
          variant="outlined"
      ></v-text-field>
      <v-tabs v-model="tab" bg-color="eraklient">
        <v-tab value="one">EST</v-tab>
        <v-tab value="two">ENG</v-tab>
        <v-tab value="three">RUS</v-tab>
      </v-tabs>
      <v-window class="py-5" v-model="tab">
        <v-window-item value="one">
          <v-text-field
              v-model=post.title
              label="Pealkiri"
              :rules="rules"
              required
              variant="outlined"
          ></v-text-field>
          <tiptap v-model="post.body"/>
        </v-window-item>
        <v-window-item value="two">
          <v-text-field
              v-model=post.title_en
              label="Pealkiri(en)"
              required
              variant="outlined"
          ></v-text-field>
          <tiptap v-model="post.body_en"/>
        </v-window-item>
        <v-window-item value="three">
          <v-text-field
              v-model=post.title_ru
              label="Pealkiri(ru)"
              required
              variant="outlined"
          ></v-text-field>
          <tiptap v-model="post.body_ru"/>
        </v-window-item>
      </v-window>
      <v-btn color="success" @click="addService" block class="mt-2">Lisa</v-btn>
    </v-form>
  </v-sheet>
</template>

<script>
import Tiptap from "@/components/Tiptap";
import PageHeader from "@/components/PageHeader";
export default {
  name: "AddKinkekaart",
  components: {PageHeader, Tiptap},
  data() {
    return {
      valid: false,
      tab: null,
      post: {
        image: "",
        title: "",
        title_en: null,
        title_ru: null,
        body: "",
        body_en: null,
        body_ru: null
      },
      rules: [
        value => {
          if (value) return true
          return 'Ei saa olla tühi.'
        },
      ]
    }
  },
  methods: {
    async addService() {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        var data = {
          image: this.post.image,
          title: this.post.title,
          title_en: this.post.title_en,
          title_ru: this.post.title_ru,
          body: this.post.body,
          body_en: this.post.body_en,
          body_ru: this.post.body_ru,
        };
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/services/kinkekaardid`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
            .then((response) => {
              console.log(response.data);
              location.assign("/kinkekaardid");
            })
            .catch((e) => {
              console.log(e);
              console.log("error");
            });
      }
    },
  }
}
</script>

<style scoped>

</style>