<!-- Toote muutmise vaheleht -->
<template>
  <page-header class="px-10">
    <h1>Muuda toodet</h1>
  </page-header>
  <v-sheet class="post-body d-flex justify-center pa-10 ga-10">
    <div class="products">
      <v-form ref="form">
        <v-text-field
            v-model=product.image
            label="Pildi URL"
            variant="outlined"
        ></v-text-field>
        <v-text-field
            v-model=product.price
            label="Hind"
            variant="outlined"
            :rules="rules"
        ></v-text-field>
        <v-text-field
            v-model=product.new_price
            variant="outlined"
            label="Soodushind"
        ></v-text-field>
        <v-select
            label="Kategooriad"
            v-model="product.category_id"
            :items="categories"
            item-title="name"
            item-value="id"
            :rules="rules"
            menu-icon="mdi-chevron-down"
            variant="outlined"
        ></v-select>
        <v-tabs v-model="lang_tab" bg-color="eraklient">
          <v-tab value="one" @click="changeLocale('et')">EST</v-tab>
          <v-tab value="two" @click="changeLocale('en')">ENG</v-tab>
          <v-tab value="three" @click="changeLocale('ru')">RUS</v-tab>
        </v-tabs>
        <v-window class="py-5" v-model="lang_tab">
          <v-window-item value="one">
            <v-text-field
                v-model=product.header
                label="Toote nimetus"
                variant="outlined"
                :rules="rules"
            ></v-text-field>
            <div class="py-5">
              <tiptap v-model="product.description"/>
            </div>
          </v-window-item>
          <v-window-item value="two">
            <v-text-field
                v-model=product.header_en
                label="Toote nimetus"
                variant="outlined"
                :rules="rules"
            ></v-text-field>
            <div class="py-5">
              <tiptap v-model="product.description_en"/>
            </div>
          </v-window-item>
          <v-window-item value="three">
            <v-text-field
                v-model=product.header_ru
                label="Toote nimetus"
                variant="outlined"
                :rules="rules"
            ></v-text-field>
            <div class="py-5">
              <tiptap v-model="product.description_ru"/>
            </div>
          </v-window-item>
        </v-window>
      </v-form>
      <v-btn color="success" @click="updateProduct" block class="mt-2">Uuenda</v-btn>
      <alert>
        <template #first-btn>Kustuta</template>
        <div class="text-h5 justify-center text-center">Kas oled kindel, et soovid toote kustutada?</div>
        <template #confirmation-btn>
          <v-btn class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="deleteProduct">Jah</v-btn>
        </template>
      </alert>
    </div>

    <div class="products">
      <v-tabs v-model="tab" bg-color="eraklient">
        <v-tab value="one">Kõik tooted</v-tab>
        <v-tab value="two">Toote vaade</v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item class="py-5" value="one">
          <v-card class="item pa-4" flat hover :href="'/tooted/' + getLinkCat(product) + '/editproduct/' + product.id">
            <img v-if="product.image" :src="product.image" draggable="false" alt=""/>
            <img v-else class="default-logo" src="../../assets/images/lohnakas.webp" alt="Lohnakas logo"/>
            <v-card-item class="mt-5 pa-0 text-left">
              <v-card-title class="font-weight-bold"> <span class="head"> {{ getHeader(product) }} </span> </v-card-title>
              <v-card-subtitle>
                <div class="prices">
                  <p class="new-price" v-if="product.new_price"> {{ product.new_price }}€</p>
                  <p class="old-price" v-if="product.new_price"> {{ product.price }}€</p>
                  <p v-if="!product.new_price"> {{ product.price }}€</p>
                </div>
              </v-card-subtitle>
              <v-card-text>
                <span class="cat"> {{ getCat(product) }}</span>
              </v-card-text>
            </v-card-item>
          </v-card>
        </v-window-item>

        <v-window-item class="pa-5" value="two">
          <v-sheet class="d-flex content-body">
            <v-sheet class="d-flex w-100 px-5">
              <div class="d-flex align-center justify-center">
                <img v-if="product.image" :src="product.image" alt=""/>
                <img v-else class="default-logo" src="../../assets/images/lohnakas.webp" alt="Lohnakas logo"/>
              </div>
            </v-sheet>
            <v-sheet class="rounded-lg order-summary" elevation="5">
              <div class="d-flex align-center flex-1-1 pa-5">
                <div class="d-flex text-left flex-column w-100">
                  <span class="text-h3 font-weight-bold"> {{ getHeader(product) }} </span>
                  <span class="cat"> {{ getCat(product) }}</span>
                  <div class="tiptap tiptap-editor" v-html=getBody(product)></div>
                  <div class="d-flex product-price font-weight-bold">
                    <p class="new-price pr-2" v-if="product.new_price"> {{ product.new_price }}€</p>
                    <p class="old-price" v-if="product.new_price"> {{ product.price }}€</p>
                    <p v-if="!product.new_price"> {{ product.price }}€</p>
                  </div>
                  <div class="d-flex align-center py-5">
                    <v-btn color="eraklient" icon="mdi-minus" size="30px"><v-icon size="20px"></v-icon></v-btn>
                    <div class="item-qty">
                      <input v-model="quantity" variant="outlined">
                    </div>
                    <v-btn color="eraklient" icon="mdi-plus" size="30px"><v-icon size="20px"></v-icon></v-btn>
                  </div>
                  <v-btn class="text-none font-weight-bold" color="eraklient" min-width="92" rounded>{{ $t('lisa_korvi') }}</v-btn>
                </div>
              </div>
            </v-sheet>
          </v-sheet>
        </v-window-item>
      </v-window>
    </div>
  </v-sheet>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Tiptap from "@/components/Tiptap";
import Alert from "@/components/Alert";
export default {
  name: "EditProduct",
  components: {Alert, Tiptap, PageHeader},
  data() {
    return {
      valid: false,
      tab: null,
      lang_tab: null,
      quantity: 1,
      categories: this.$store.state.categories,
      product: [],
      rules: [
        value => {
          if (value) return true
          return 'Ei saa olla tühi.'
        },
      ]
    }
  },
  methods: {
    fetchAProduct(id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/products/${id}`)
          .then((response) => response.json())
          .then((data) => (this.product = data))
          .catch((err) => console.log(err.message));
    },
    async updateProduct() {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/products/${this.product.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.product),
        })
            .then((response) => {
              console.log(response.data);
              location.assign("/tooted");
            })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    deleteProduct() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/products/${this.product.id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      })
          .then((response) => {
            console.log(response.data);
            location.assign("/tooted");
          })
          .catch((e) => {
            console.log(e);
          });
    },
    changeLocale(value) {
      this.$i18n.locale = value;
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.description_en !== null) ? content.description_en : (this.$i18n.locale === 'ru' && content.description_ru !== null) ? content.description_ru : content.description;
    },
    getCat(content) {
      let id = content.category_id;
      let category = this.categories.find(cat => cat.id === id);
      if (category) return (this.$i18n.locale === 'en' && category.name_en !== null) ? category.name_en : (this.$i18n.locale === 'ru' && category.name_ru !== null) ? category.name_ru : category.name;
    },
    getLinkCat(content) {
      return this.$store.getters.getLinkCat(content);
    }
  },
  created() {
    this.$store.dispatch('fetchProducts');
    this.$store.dispatch('fetchCategories');
  },
  mounted() {
    this.fetchAProduct(this.$route.params.id);
  }
}
</script>
<style scoped>
.v-card-text {
  padding: 0;
}
</style>