<!-- Ostukorvi vaheleht -->
<template>
  <div class="page-properties">
    <page-header>
      <h1>{{t('ostukorv')}}</h1>
      <v-dialog v-model="empty_cart" width="auto" transition="none">
        <v-card>
          <v-toolbar color="white" :title="t('kinnitus')">
            <v-btn icon="mdi-close" @click="empty_cart = false"></v-btn>
          </v-toolbar>
          <v-card-text>
            <div class="text-h5 justify-center text-center">{{t('eemalda_conf')}}</div>
          </v-card-text>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-btn class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="deleteCart" @click="empty_cart = false; showToastSuccess(t('tuhjendatud'))" v-bind="props">{{t('jah')}}</v-btn>
            <v-btn class="text-none font-weight-bold" min-width="92" rounded @click="empty_cart = false">{{t('ei')}}</v-btn>
          </div>
        </v-card>
      </v-dialog>
      <div class="pa-4">
        <v-btn class="mb-2" v-if="Object.keys(cart).length > 0" :title="t('tühjenda_korv')" icon="mdi-delete" @click="empty_cart = true" aria-haspopup="true" :aria-expanded="valid"></v-btn>
      </div>
    </page-header>

    <v-sheet v-if="Object.keys(cart).length > 0" class="cart-body">
      <v-sheet class="w-100">
        <v-list class="px-2">
          <v-list-item class="pa-0 mb-3 rounded-lg" elevation="5" v-for="(item, productId) in cart" :key="productId">
            <div class="d-flex w-100 pa-3 align-center">
              <a class="d-flex align-center flex-1-1 mr-6" :href="'/tooted/' + getLinkCat(item.product) + '/' + productId" draggable="false">
                <div class="d-flex align-center justify-center mr-5 cart-img">
                  <v-img v-if="item.product.image" class="img-shadow" width="80" height="80" cover :src="item.product.image" draggable="false"></v-img>
                  <img v-else src="../../assets/images/lohnakas.webp" alt="Lohnakas logo" draggable="false"/>
                </div>
                <div class="d-flex flex-column w-100">
                  <span class="font-weight-bold text-left"> {{ getHeader(item.product) }} </span>
                </div>
              </a>
              <div class="d-flex flex-column">
                <div class="flex-column d-flex text-center">
                  <div class="product-price font-weight-bold">
                    <span class="new-price" v-if="item.product.new_price"> {{ item.product.new_price }}€/{{$t('tk')}}</span>
                    <span class="old-price" v-if="item.product.new_price"> {{ item.product.price }}€</span>
                    <span v-if="!item.product.new_price"> {{ item.product.price }}€/{{$t('tk')}}</span>
                  </div>
                </div>
                <div class="d-flex align-center">
                  <v-btn aria-label="Decrease Quantity" color="eraklient" @click="decreaseQuantity(productId)" icon="mdi-minus" size="30px"><v-icon size="20px"></v-icon></v-btn>
                  <div class="item-qty">
                    <input :title="$t('valige_kogus')" v-model="item.quantity">
                  </div>
                  <v-btn aria-label="Increase Quantity" color="eraklient" @click="increaseQuantity(productId)" icon="mdi-plus" size="30px"><v-icon size="20px"></v-icon></v-btn>
                </div>
              </div>
            </div>
          </v-list-item>
          <v-divider class="mobile-divider"></v-divider>
        </v-list>
      </v-sheet>
      <v-sheet class="rounded-lg checkout text-center pa-4" elevation="5">
        <h2 class="text-left">{{ t('tellimus') }}</h2>
        <v-list role="listbox">
          <v-list-item class="pa-0" v-for="(item, productId) in cart" :key="productId">
            <div class="order-items">
              <div>{{ item.quantity }}x {{ getHeader(item.product) }}</div>
              <div>{{ ((item.product.new_price || item.product.price) * item.quantity).toFixed(2) }}€</div>
            </div>
          </v-list-item>
          <v-divider class="mx-6"></v-divider>
        </v-list>
        <div class="order-items pt-5">
          <div class="text-uppercase">{{t('kokku')}}</div>
          <div class="font-weight-bold order-price"> {{ ($store.getters.cartTotal).toFixed(2) }}€ </div>
        </div>
        <form class="mt-5" @submit.prevent="submit">
          <v-text-field
              variant="outlined"
              v-model="name.value.value"
              :error-messages="name.errorMessage.value"
              :label="t('nimi')"
          ></v-text-field>
          <v-text-field
              variant="outlined"
              v-model="phone.value.value"
              :error-messages="phone.errorMessage.value"
              :label="t('tel')"
          ></v-text-field>
          <v-text-field
              variant="outlined"
              v-model="email.value.value"
              :error-messages="email.errorMessage.value"
              :label="t('email')"
          ></v-text-field>
          <v-select
              variant="outlined"
              menu-icon="mdi-chevron-down"
              v-model="select_delivery.value.value"
              :items="topics"
              :error-messages="select_delivery.errorMessage.value"
              :label="t('kattesaamine')"
          ></v-select>
          <v-select
              variant="outlined"
              menu-icon="mdi-chevron-down"
              v-model="select_payment.value.value"
              :items="topics_payment"
              :error-messages="select_payment.errorMessage.value"
              :label="t('tasumine')"
          ></v-select>
          <v-textarea
              variant="outlined"
              v-model="message.value.value"
              :label="t('sonum_valik')"
          ></v-textarea>
          <input type="text" name="honeypot" id="honeypot" style="display: none;" v-model="honeypot.value.value">
          <v-btn color="eraklient" class="d-flex w-100" type="submit">{{t('saada')}}</v-btn>
        </form>
      </v-sheet>
    </v-sheet>

    <v-dialog v-model="orderPlaced">
      <v-sheet class="pa-4 text-center mx-auto" elevation="12" max-width="600" rounded="lg" width="100%">
        <v-icon class="mb-5" color="success" icon="mdi-check-circle" size="112"></v-icon>
        <h2 class="text-h5 mb-6">{{t('order_title')}}</h2>
        <p class="mb-4 text-medium-emphasis text-body-2">{{t('order_text')}}</p>
        <v-divider class="mb-4"></v-divider>
        <div class="text-end">
          <v-btn class="text-none" @click="orderPlaced = false" color="success" variant="flat" width="90" rounded>OK</v-btn>
        </div>
      </v-sheet>
    </v-dialog>

    <div class="empty-cart" v-if="Object.keys(cart).length === 0">
      <p>{{t('ostukorv_tuhi')}}</p>
      <v-btn color="eraklient" prepend-icon="mdi-chevron-left" to="/tooted" :text="t('tagasi_poodi')"></v-btn>
    </div>
  </div>
</template>

<script>
import {ref, watchEffect} from 'vue';
import PageHeader from "@/components/PageHeader";
import {toast} from "vue3-toastify";
import {useField, useForm} from "vee-validate";
import { useStore } from 'vuex'
import {useI18n} from "vue-i18n";

export default {
  name: "Cart",
  components: {PageHeader},
  data() {
    return {
      valid: false,
      empty_cart: false
    };
  },
  setup() {
    const { t } = useI18n()
    const store = useStore();
    const { handleSubmit, handleReset } = useForm({
      validationSchema: {
        name (value) {
          if (value?.length > 1) return true
          return t('sisesta_nimi')
        },
        phone (value) {
          if (value?.length > 6 && /[0-9-]+/.test(value)) return true
          return t('sisesta_tel')
        },
        email (value) {
          if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) return true
          return t('sisesta_email')
        },
        select_delivery (value) {
          if (value) return true
          return t('kattesaamise_viis')
        },
        select_payment (value) {
          if (value) return true
          return t('tasumise_viis')
        },
      },
    })
    const name = useField('name')
    const phone = useField('phone')
    const email = useField('email')
    const select_delivery = useField('select_delivery')
    const select_payment = useField('select_payment')
    const message = useField('message')
    const honeypot = useField('honeypot')

    const topics = ref([
      t('pakiautomaat'),
      t('ise_jargi'),
      t('Muu')
    ])

    const topics_payment = ref([
      t('sularaha'),
      t('arve'),
      t('ulekanne')
    ])

    const deleteCart = () => {
      store.commit('deleteCart');
    };

    const orderPlaced = ref(false);

    const placeOrder = () => {
      orderPlaced.value = true;
    };

    const submit = handleSubmit(async values => {
      if (honeypot.value.value) {
        toast.success(t('mess_succ'));
        handleReset();
        return
      }
      try {
        const newValues = {
          ...values,
          subject: "E-poe tellimus",
          total: (store.getters.cartTotal).toFixed(2),
          cart: Object.keys(store.state.cart).map(productId => {
            const product = store.state.cart[productId].product;
            const quantity = store.state.cart[productId].quantity;
            return {
              header: product.header,
              price: product.price,
              new_price: product.new_price,
              quantity
            }
          })
        };
        const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/send-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newValues)
        });

        const data = await response.text();
        console.log(data);
        if (response.ok) {
          window.scrollTo(0, 0);
          deleteCart();
          placeOrder();
          handleReset();
        }
        else {
          toast.error(t('order_err'));
        }
      }
      catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    });

    return {
      name,
      phone,
      email,
      select_delivery,
      select_payment,
      topics,
      topics_payment,
      message,
      submit,
      orderPlaced,
      deleteCart,
      t,
      honeypot
    }
  },
  computed: {
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
    cart() {
      return this.$store.state.cart;
    }
  },
  methods: {
    removeFromCart(productId) {
      this.$store.dispatch('removeFromCart', productId);
    },
    increaseQuantity(productId) {
      this.$store.commit('increaseQuantity', productId);
    },
    decreaseQuantity(productId) {
      this.$store.commit('decreaseQuantity', productId);
    },
    showToastSuccess(value) {
      toast.success(value, {
        autoClose: 3000
      })
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getLinkCat(content) {
      return this.$store.getters.getLinkCat(content);
    }
  },
  mounted() {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      this.$store.commit('loadCart', JSON.parse(savedCart));
    }
    this.cartItemsWatchers = Object.keys(this.cart).map(productId =>
        watchEffect(() => {
          if (this.cart[productId]) {
            const quantity = this.cart[productId].quantity;
            this.$store.commit('updateQuantity', { productId, quantity });
          }
        })
    )
  },
  beforeRouteLeave(to, from, next) {
    this.orderPlaced = false;
    next();
  }
}
</script>

<style scoped>
img {
  width: 80px;
  height: 80px;
  object-fit: scale-down;
}

.v-card-title {
  font-size: 0.8em;
}

a:hover {
  color: #3b71f7;
}

.cart-body {
  display: flex;
  gap: 0 20px;
}

.order-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.checkout {
  min-width: 400px;
  height: 100%;
}

.mobile-divider {
  display: none;
}

.empty-cart {
  text-align: left;
}

@media (max-width: 400px) {
  .cart-img {
    display: none !important;
  }
}

@media (max-width: 1000px) {
  .cart-body {
    flex-direction: column;
  }
  .checkout {
    min-width: unset;
  }
  .empty-cart {
    text-align: center;
  }
  .rounded-lg {
    border-radius: 0px !important;
  }
  .elevation-5 {
    box-shadow: none !important;
  }
  .pa-5 {
    padding-bottom: 20px !important;
  }
  .mobile-divider {
    display: block;
  }
}
</style>