<template>
  <div class="form">
    <h3>SignUp</h3>
    <label for="username">Username</label>
    <input type="text" name="username"  required v-model="email">
    <label for="password">Password</label>
    <input type="password" name="password" required v-model="password">
    <div v-if="errMsg">{{errMsg}} </div>
    <button @click="SignUp" class="SignUp">SignUp</button>
  </div>
</template>

<script>
export default {
name: "SignUp", 
data: function() {
    return {
   email: '',
   password: '',
   errMsg: '',
  }
  },
watch: {
    password(value) {
      this.password = value;
    }
  },
  methods: {
    SignUp() {
      var data = {
        email: this.email,
        password: this.password
      };
      fetch(`${process.env.VUE_APP_SERVER_URL}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
          credentials: 'include', 
          body: JSON.stringify(data),
      })
      .then((response) => response.json())
      .then((data) => {
      console.log(data);
      this.$router.push("/login");
      })
      .catch((e) => {
        console.log(e);
        console.log("error");
      });
    },
  },
  }
</script>

<style scoped>
.form {
  max-width: 420px;
  margin: 30px auto;
  background: #5A6267;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}
h3 {
  text-align: center;
  color: lightgray;
}
label {
  color: lightgray;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid white;
  color: black;
}
button {
  background: gray;
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  color: lightgray;
  border-radius: 20px;
  align-items: center;
  text-align: center;
}
div {
  padding: 10px 20px;
  margin-top: 20px;
}
</style>