<!-- Avalehe tagasiside muutmise vaheleht -->
<template>
  <v-sheet class="page-properties pa-4">
    <page-header>
      <h1>Muuda tagasisidet</h1>
    </page-header>
    <v-form ref="form">
      <v-textarea
          v-model=feedback.body
          label="Sisu"
          :rules="rules"
          required
          variant="outlined"
      ></v-textarea>
      <v-textarea
          v-model=feedback.body_en
          label="Sisu(en)"
          required
          variant="outlined"
      ></v-textarea>
      <v-textarea
          v-model=feedback.body_ru
          label="Sisu(ru)"
          required
          variant="outlined"
      ></v-textarea>
    </v-form>
    <v-btn color="success" @click="updateFeedback" block class="mt-2">Uuenda</v-btn>
    <alert>
      <template #first-btn>Kustuta</template>
      <div class="text-h5 justify-center text-center">Kas oled kindel, et soovid postituse kustutada?</div>
      <template #confirmation-btn>
        <v-btn class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="deleteFeedback">Jah</v-btn>
      </template>
    </alert>
  </v-sheet>
</template>

<script>
import Alert from "@/components/Alert";
import PageHeader from "@/components/PageHeader";
export default {
  name: "EditFeedback",
  components: {PageHeader, Alert},
  data() {
    return {
      valid: false,
      feedback: [],
      rules: [
        value => {
          if (value) return true
          return 'Ei saa olla tühi.'
        },
      ]
    };
  },
  methods: {
    fetchAFeedback(id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/feedbacks/${id}`)
          .then((response) => response.json())
          .then((data) => (this.feedback = data))
          .catch((err) => console.log(err.message));
    },
    async updateFeedback () {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/feedbacks/${this.feedback.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.feedback),
        })
            .then((response) => {
              console.log(response.data);
              this.$router.push("/");
            })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    deleteFeedback() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/feedbacks/${this.feedback.id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      })
          .then((response) => {
            console.log(response.data);
            this.$router.push("/");
          })
          .catch((e) => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.fetchAFeedback(this.$route.params.id);
  },
}
</script>

<style scoped>

</style>