<!-- Komponent, mida kasutatakse kõikide teenuste kuvamiseks
Inspiratsioon: https://motion.vueuse.org/features/motion-instance/ -->

<template>
  <v-btn v-if="isLoggedIn" color="success" @click="putServices" class="mt-2">Uuenda</v-btn>
  <div v-for="(content, index) in contents" :key="index" class="content">
    <div v-motion :initial="{ x: index % 2 === 0 ? this.x_1 : this.x_2, opacity: 0 }" :visibleOnce="{ x: 0, opacity: 1, transition: { stiffness: 100, damping: 50, mass: 1, delay: 700 }}">
      <div v-if="isLoggedIn" class="d-flex justify-center">
        <a class="mr-3" :href="href + content.id">
          <i class="fa fa-cog"></i>
        </a>
        <div class="item-qty">
          <input v-model="content.id">
        </div>
      </div>
      <div class="d-flex w-100 slider-body align-center justify-center">
        <div v-if="index % 2 === 1" class="image-left">
          <img class="logo" v-if="content.image" :src="content.image" alt="pilt"/>
          <img v-else class="default-logo" src="../assets/images/lohnakas.webp" alt="lohnakas logo"/>
        </div>
        <div class="d-flex flex-column w-100">
          <div class="text-left">
            <span class="text-h4 font-weight-bold slider-header">{{ getTitle(content) }}</span>
            <p class="slider-text py-5 tiptap tiptap-editor" v-html="getBody(content)"></p>
            <div v-if="$route.path === '/kinkekaardid'">
              <ask-giftcard :service="content"></ask-giftcard>
            </div>
            <div v-else-if="$route.path === '/teenused/lohnastuslahendused'">
              <ask-lohnastus :service="content"></ask-lohnastus>
            </div>
            <div v-else>
              <ask-offer :service="content"></ask-offer>
            </div>
          </div>
        </div>
        <div v-if="index % 2 === 0 || this.isPhone" class="image-right">
          <img v-if="content.image" :src="content.image" alt="pilt"/>
          <img v-else class="default-logo" src="../assets/images/lohnakas.webp" alt="lohnakas logo"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { motion } from '@vueuse/motion';
import AskOffer from "@/components/AskOffer";
import AskLohnastus from "@/components/AskLohnastus";
import AskGiftcard from "@/components/AskGiftcard.vue";
export default {
  name: "ScrollSlider",
  props: {
    fetchData: {
      type: Function,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    service: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      shownContents: [],
      scrollPosition: 0,
      isPhone: false,
      contents: [],
      x_1: 300,
      x_2: -300,
    };
  },
  methods: {
    fetchServices() {
      this.fetchData()
          .then((data) => {
            this.contents = data;
          })
          .catch((err) => console.log(err.message));
    },
    async putServices() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/services/${this.service}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.contents),
      })
          .then((response) => {
            console.log(response.data);
            if (window.location.pathname.startsWith('/teenused')) {
              location.assign(`/teenused/${this.service}`);
            } else {
              location.assign(`/${this.service}`);
            }
          })
          .catch((e) => {
            console.log(e);
            console.log("error");
          });
    },
    updateScrollPosition() {
      this.isPhone = window.innerWidth < 650;
      if (this.isPhone) {
        this.x_1 = 20;
        this.x_2 = -20;
      }
    },
    getTitle(content) {
      return (this.$i18n.locale === 'en' && content.title_en !== null) ? content.title_en : (this.$i18n.locale === 'ru' && content.title_ru !== null) ? content.title_ru : content.title;
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  },
  components: {
    AskGiftcard,
    AskLohnastus,
    AskOffer,
    motion,
  },
  mounted() {
    this.updateScrollPosition();
    window.addEventListener('scroll', this.updateScrollPosition);
    this.fetchServices();
    console.log("mounted");
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateScrollPosition);
  },
}
</script>

<style scoped>
img {
  max-height: 400px;
  height: auto;
  width: 400px;
  object-fit: cover;
}

img.default-logo {
  height: auto;
  width: 400px;
  object-fit: scale-down;
}

.image-left {
  padding-right: 40px;
}

.image-right {
  padding-left: 40px;
}

.content {
  padding: 40px 0 40px 0;
}



@media (max-width: 1000px) {
  .slider-text {
    font-size: 0.85em;
  }
  .slider-header {
    font-size: 1.8em !important;
  }
}

@media (max-width: 800px) {
  .slider-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  .image-left {
    display: none;
  }
  .image-right {
    padding-left: 0;
  }
  img {
    width: 100% !important;
    height: auto !important;
  }
}

@media (max-width: 650px) {
  .content {
    padding: 0 0 20px 0;
  }
}
</style>