<!-- E-poe kategooriate lisamise/muutmise vaheleht -->
<template>
  <page-header class="px-10">
    <h1>Lisa/muuda kategoooriat</h1>
  </page-header>
  <v-sheet class="post-body d-flex justify-center pa-10 ga-10">
    <div class="products">
      <h2 class="d-flex justify-center">Uus kategooria</h2>
      <v-form ref="form">
        <v-text-field
            v-model="category.name"
            label="Kategooria"
            variant="outlined"
            :rules="rules"
        ></v-text-field>
        <v-text-field
            v-model="category.name_en"
            label="Kategooria(en)"
            variant="outlined"
        ></v-text-field>
        <v-text-field
            v-model="category.name_ru"
            label="Kategooria(ru)"
            variant="outlined"
        ></v-text-field>
      </v-form>
      <v-btn color="success" @click="addCategory"  class="mt-2">Lisa</v-btn>
    </div>
    <div class="products">
      <h2 class="d-flex justify-center">Muuda kategooriat</h2>
      <v-list v-if="!edit" v-for="category in categories">
        <v-list-item active="" active-color="eraklient">
          <div class="d-flex justify-space-between align-center">
            {{category.name}}
            <span @click="changeCat(category)" class="clickable">
              <i class="fa fa-cog pa-2"></i>
            </span>
          </div>
        </v-list-item>
      </v-list>
      <v-form v-if="edit" ref="form">
        <v-text-field
            v-model="cat.name"
            label="Kategooria"
            variant="outlined"
            :rules="rules"
        ></v-text-field>
        <v-text-field
            v-model="cat.name_en"
            label="Kategooria(en)"
            variant="outlined"
        ></v-text-field>
        <v-text-field
            v-model="cat.name_ru"
            label="Kategooria(ru)"
            variant="outlined"
        ></v-text-field>
        <v-btn block color="eraklient" @click="this.edit = false">Tagasi</v-btn>
        <v-btn block color="success" @click="updateCategory(cat.id)" class="mt-2">Uuenda</v-btn>
        <alert>
          <template #first-btn>Kustuta</template>
          <div class="text-h5 justify-center text-center">Kas oled kindel, et soovid kategooria kustutada?</div>
          <template #confirmation-btn>
            <v-btn class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="deleteCategory(cat.id)">Jah</v-btn>
          </template>
        </alert>
      </v-form>
    </div>
  </v-sheet>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Alert from "@/components/Alert";
export default {
  name: "AddCategory",
  components: {Alert, PageHeader},
  data() {
    return {
      valid: false,
      edit: false,
      categories: this.$store.state.categories,
      cat: [],
      category: {
        name: null,
        name_en: null,
        name_ru: null
      },
      rules: [
        value => {
          if (value) return true
          return 'Ei saa olla tühi.'
        },
      ],
    };
  },
  methods: {
    async fetchACategory(id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/categories/${id}`)
          .then((response) => response.json())
          .then((data) => (this.cat = data))
          .catch((err) => console.log(err.message));
    },
    async addCategory() {
      const {valid} = await this.$refs.form.validate()

      if (valid) {
        var data = {
          name: this.category.name,
          name_en: this.category.name_en,
          name_ru: this.category.name_ru
        };
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/categories`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
            .then((response) => {
              console.log(response.data);
              location.assign("/tooted");
            })
            .catch((e) => {
              console.log(e);
              console.log("error");
            });
      }
    },
    async updateCategory(id) {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/categories/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.cat),
        })
            .then((response) => {
              console.log(response.data);
              location.assign("/tooted");
            })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    deleteCategory(id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/categories/${id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      })
          .then((response) => {
            console.log(response.data);
            location.assign("/tooted");
          })
          .catch((e) => {
            console.log(e);
          });
    },
    changeCat(category) {
      this.fetchACategory(category.id);
      this.edit = true;
    }
  }
}
</script>

<style scoped>

</style>