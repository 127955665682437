<template>
  <div class="page-properties px-5">
    <page-header>
      <h1>{{$t('lõhnastuslahendused')}}</h1>
      <v-btn v-if="isLoggedIn" color="success" to="/teenused/lohnastuslahendused/addlohnastus">lisa +</v-btn>
    </page-header>
    <a v-if="isLoggedIn" href="/teenused/lohnastuslahendused/editlohnastusinfo">
      <i class="fa fa-cog"></i>
    </a>
    <simple-quote><div class="tiptap tiptap-editor" v-html=getBody(info)></div></simple-quote>
    <scroll-slider :fetchData="fetchDataForLohnastused" :href="'/teenused/lohnastuslahendused/editlohnastus/'" service="lohnastused"></scroll-slider>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ScrollSlider from "@/components/ScrollSlider";
import SimpleQuote from "@/components/SimpleQuote.vue";
export default {
  name: "LohnastusedView",
  components: {SimpleQuote, ScrollSlider, PageHeader},
  data() {
    return {
      isLoggedIn: false,
      info: []
    };
  },
  methods: {
    fetchDataForLohnastused() {
      return fetch(`${process.env.VUE_APP_SERVER_URL}/api/services/lohnastused`)
          .then((response) => response.json())
          .catch((err) => console.log(err.message));
    },
    fetchInfo() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/texts/9`)
          .then((response) => response.json())
          .then((data) => (this.info = data))
          .catch((err) => console.log(err.message));
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  mounted() {
    this.fetchInfo();
    console.log("mounted");
  }
}
</script>

<style scoped>

</style>