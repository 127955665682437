<!-- header navbar animation: https://www.30secondsofcode.org/css/s/hover-underline-animation/
     shrink animation: https://codepen.io/tomdurkin/pen/AvaGgN -->

<template>
  <header class="header" id="mainHeader">
    <div class="header-content">
      <router-link to="/" class="d-flex">
        <img class="logo" src="../assets/images/lohnakas_era.svg" alt="main-logo" draggable="false">
      </router-link>
      <!-- Desktop navbar -->
      <ul class="nav py-2">
        <li class="dropdown">
          <!-- Shopping cart sidebar -->
          <shopping-cart></shopping-cart>
        </li>
        <li>
          <div class="d-flex">
            <v-menu location="bottom">
              <template v-slot:activator="{ props }">
                <img v-bind="props" :src="this.$i18n.selectedLang" class="flag-icon" :alt="this.$i18n.locale" aria-hidden="true">
              </template>
              <v-sheet>
                <span class="lang-select px-1" v-for="item in langs" :key="item" :title="item.text" @click="changeLocale(item.value, item.src)">
                  <img :src="item.src" class="flag-icon" :alt="item.text">
                </span>
              </v-sheet>
            </v-menu>
          </div>
        </li>
        <li><span>{{ $t('ettevote') }}</span>
          <ul class="dropdown-content">
            <li><router-link to="/asutajast">{{ $t('asutajast') }}</router-link></li>
            <li><router-link to="/kaubamark">{{ $t('kaubamärk') }}</router-link></li>
          </ul>
        </li>
        <li>
          <router-link aria-label="teenused" to="/teenused">
            <span>{{ $t('teenused') }}</span>
            <ul class="dropdown-content">
              <li><router-link to="/teenused/lohnastuslahendused">{{ $t('lõhnastuslahendused') }}</router-link></li>
              <li><router-link to="/teenused/meeskonnakoolitused">{{ $t('meeskonnakoolitused') }}</router-link></li>
              <li><router-link to="/teenused/uritused">{{ $t('tutvu_uritus') }}</router-link></li>
              <li><router-link to="/teenused/huviringid">{{ $t('tutvu_huviring') }}</router-link></li>
            </ul>
          </router-link>
        </li>
        <li><span>{{ $t('tooted') }}</span>
          <ul class="dropdown-content">
            <li><router-link to="/tooted">{{ $t('epood') }}</router-link></li>
            <li><router-link to="/tooteinfo">{{ $t('tooteinfo') }}</router-link></li>
            <li><router-link to="/ninatrenn">{{ $t('ninatrenn') }}</router-link></li>
            <li><router-link to="/kinkekaardid">{{ $t('kinkekaardid') }}</router-link></li>
          </ul>
        </li>
        <li><router-link to="/uudised"><span>{{ $t('uudised') }}</span></router-link></li>
        <li><router-link to="/kontakt"><span>{{ $t('kontakt') }}</span></router-link></li>
        <li class="pr-0">
          <send-request></send-request>
        </li>
        <li v-if="isLoggedIn" class="pr-0">
          <user-profile></user-profile>
        </li>
      </ul>
      <!-- Mobile navbar -->
      <div class="mobile-nav">
        <v-row justify="center">
          <v-dialog v-model="dialog" fullscreen  transition="slide-x-reverse-transition">
            <template v-slot:activator="{ props }">
              <div class="d-flex pr-2"><v-app-bar-nav-icon :title="$t('menuu')" :aria-label="$t('menuu')" v-bind="props"></v-app-bar-nav-icon></div>
            </template>
            <v-card>
              <v-toolbar color="white">
                <v-btn icon dark @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{$t('menuu')}}</v-toolbar-title>
              </v-toolbar>
              <v-list>
                <v-list-group :value="$t('ettevote')">
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" :title="$t('ettevote')"></v-list-item>
                  </template>
                  <v-list-item to="/asutajast" @click="dialog = false" :title="$t('asutajast')"></v-list-item>
                  <v-list-item to="/kaubamark" @click="dialog = false" :title="$t('kaubamärk')"></v-list-item>
                </v-list-group>
                <v-list-group aria-label="teenused" :value="$t('teenused')">
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" :title="$t('teenused')"></v-list-item>
                  </template>
                  <v-list-item to="/teenused/lohnastuslahendused" @click="dialog = false" :title="$t('lõhnastuslahendused')"></v-list-item>
                  <v-list-item to="/teenused/meeskonnakoolitused" @click="dialog = false" :title="$t('meeskonnakoolitused')"></v-list-item>
                  <v-list-item to="/teenused/uritused" @click="dialog = false" :title="$t('tutvu_uritus')"></v-list-item>
                  <v-list-item to="/teenused/huviringid" @click="dialog = false" :title="$t('tutvu_huviring')"></v-list-item>
                </v-list-group>
                <v-list-group :value="$t('tooted')">
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" :title="$t('tooted')"></v-list-item>
                  </template>
                  <v-list-item to="/tooted" @click="dialog = false" :title="$t('epood')"></v-list-item>
                  <v-list-item to="/tooteinfo" @click="dialog = false" :title="$t('tooteinfo')"></v-list-item>
                  <v-list-item to="/ninatrenn" @click="dialog = false" :title="$t('ninatrenn')"></v-list-item>
                  <v-list-item to="/kinkekaardid" @click="dialog = false" :title="$t('kinkekaardid')"></v-list-item>
                </v-list-group>
                <v-list-item to="/uudised" @click="dialog = false">
                  <v-list-item-title>{{$t('uudised')}}</v-list-item-title>
                </v-list-item>
                <v-list-item to="/kontakt" @click="dialog = false">
                  <v-list-item-title>{{ $t('kontakt') }}</v-list-item-title>
                </v-list-item>
                <v-list-group value="lang">
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props"><img :src="this.$i18n.selectedLang" class="flag-icon" :alt="this.$i18n.locale" aria-hidden="true"></v-list-item>
                  </template>
                  <v-list-item class="lang-select px-1" v-for="item in langs" :key="item" @click="changeLocale(item.value, item.src)">
                    <img :src="item.src" class="flag-icon" :alt="item.text" :title="item.text">
                  </v-list-item>
                </v-list-group>
                <v-list-item>
                  <send-request></send-request>
                </v-list-item>
                <v-list-item v-if="isLoggedIn">
                  <v-btn block color="error" @click="Logout">Log out</v-btn>
                </v-list-item>
              </v-list>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </div>
  </header>
</template>

<script>
import {toast} from "vue3-toastify";
import auth from "@/auth";
import SendRequest from "@/components/SendRequest";
import UserProfile from "@/components/UserProfile";
import ShoppingCart from "@/components/ShoppingCart";

export default {
  name: "MainHeader",
  components: {ShoppingCart, UserProfile, SendRequest},
  data() {
    return {
      dialog: false,
      drawer: false,
      group: null,
      langs: [
        { text: 'EST', value: 'et', src: require('@/assets/images/flag-estonia.svg')},
        { text: 'ENG', value: 'en', src: require('@/assets/images/flag-gb.svg')},
        { text: 'RUS', value: 'ru', src: require('@/assets/images/flag-russia.svg')}
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  },
  methods: {
    Logout() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/auth/logout`, {
        credentials: 'include',
      })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            console.log('jwt removed');
            this.$store.commit('SET_LOGIN_STATUS', false);
            this.dialog = false;
            toast.success("Logged out");
          })
          .catch((e) => {
            console.log(e);
            console.log("error logout");
          });
    },
    shrinkHeader() {
      if (window.innerWidth > 1000) {
        if (window.pageYOffset > this.sticky) {
          this.header.classList.add("sticky");
        } else {
          this.header.classList.remove("sticky");
        }
      }
    },
    async checkAuthentication() {
      let authResult = await auth.authenticated();
      if (this.isLoggedIn) {
        if (!authResult) {
          this.$store.commit('SET_LOGIN_STATUS', false);
          toast.error("Logged out");
        } else {
          setTimeout(this.checkAuthentication, 3600000);
        }
      }
    },
    async changeLocale(value, item) {
      localStorage.setItem('locale', value);
      localStorage.setItem('selectedLang', item);
      this.$i18n.locale = localStorage.getItem('locale', value);
      this.$i18n.selectedLang = localStorage.getItem('selectedLang', item);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.shrinkHeader);
    this.header = document.getElementById("mainHeader");
    this.sticky = this.header.offsetTop;
  },

  created() {
    if (this.isLoggedIn) {
      this.checkAuthentication();
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.shrinkHeader);
  }
}
</script>

<style scoped>
.flag-icon {
  width: 25px;
  height: auto;
  cursor: pointer;
  display: flex;
}

.lang-select {
  display: flex;
  align-items: center;

}

.header {
  width: 100%;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.75);
  z-index: 100;
  background: #ffffff;
  position: fixed;
  top: 0;
}

.header-content {
  display: flex;
  max-width: 1240px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: auto;
}

.lang-nav li {
  display: none;
}

.lang-nav li:hover {
  display: block;
}

.logo {
  width: 150px;
  height: auto;
  padding:  15px 15px 15px 0;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}

.sticky .logo {
  width: 110px;
  height: auto;
}

.nav {
  display: flex;
  float: right;
  align-items: center;
  list-style: none;
  overflow: hidden;
  font-size: 1.1em;
  cursor: default;
}

.nav li {
  padding: 0 1em 0 1em;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  padding: 12px 16px;
  display: block;
  text-align: left;
  font-size: 0.95em;
  line-height: 30px;
}

.dropdown-content li {
  padding: 0;
}

.nav li:hover .dropdown-content {
  display: block;
}

.nav .dropdown-content li:hover {
  background: #ddd;
}

.dropdown-content-children {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  background: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content li:hover .dropdown-content-children {
  display: block;
}

.nav span {
  display: inline-block;
  position: relative;
  transform-origin: left center;
}

.nav li:hover span::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

ul {
  padding: 0;
  list-style: none;
}

.nav span::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #3b71f7;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav span:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
}

.mobile-nav {
  display: none;
}

@media (max-width: 1200px) {
  .header {
    position: sticky;
  }
}

@media (max-width: 1000px) {
  li {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  .logo {
    width: 110px;
  }
  .header-content {
    padding: 16px;
  }
  .logo {
    padding: 0;
  }
}
</style>