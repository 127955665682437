<template>
  <div class="page-properties px-5">
    <page-header>
      <h1>{{$t('tutvu_huviring')}}</h1>
      <v-btn v-if="isLoggedIn" color="success" to="/teenused/huviringid/addhuviring">lisa +</v-btn>
    </page-header>
    <scroll-slider :fetchData="fetchDataForHuviringid" :href="'/teenused/huviringid/edithuviring/'" service="huviringid"></scroll-slider>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ScrollSlider from "@/components/ScrollSlider";
export default {
  name: "HuviringidView",
  components: {ScrollSlider, PageHeader},
  methods: {
    fetchDataForHuviringid() {
      return fetch(`${process.env.VUE_APP_SERVER_URL}/api/services/huviringid`)
          .then((response) => response.json())
          .catch((err) => console.log(err.message));
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  }
}
</script>
