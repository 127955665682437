<!-- Üksiku uudise vaheleht -->
<template>
  <v-sheet class="main-content">
    <v-sheet class="mx-auto" v-if="isLoading">
      <v-skeleton-loader type="heading, image, paragraph"></v-skeleton-loader>
    </v-sheet>
    <v-sheet v-else class="d-flex w-100 mx-auto post-body flex-column">
      <a class="d-flex justify-center pt-2" v-if="$store.state.isLoggedIn" :href="'/uudised/editpost/' + post.id">
        <i class="fa fa-cog"></i>
      </a>
      <div class="post-content">
        <v-list-item-subtitle>{{ this.getDate(post.date) }}</v-list-item-subtitle>
        <h2 class="py-6 text-center">{{ getHeader(post) }}</h2>
        <img v-if="post.image" class="img-shadow" alt="Postituse pilt" :src="post.image" />
        <img v-else class="default-logo" alt="lõhnakas logo" src="../../assets/images/lohnakas.webp" />
        <div class="tiptap tiptap-editor" v-html=getBody(post)></div>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapGetters } from 'vuex';

export default {
  name: "ViewPost",
  components: {PageHeader},
  data() {
    return {
      post: [],
      isLoading: true
    }
  },
  computed: {
    ...mapGetters([
      'formatDate',
    ]),
  },
  methods: {
    fetchAPost(id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/posts/${id}`)
          .then((response) => response.json())
          .then((data) => {
            this.post = data;
            this.isLoading = false;
          })
          .catch((err) => console.log(err.message));
    },
    getDate(date) {
      return this.formatDate(date);
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    }
  },
  mounted() {
    this.fetchAPost(this.$route.params.id);
  }
}
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: 20px 0;
}

.img-shadow {
  width: 100%;
  height: 100%;
}

.v-list-item-title {
  white-space: break-spaces;
  line-height: 1em;
}

.post-body {
  border-width: thin;
  max-width: 940px;
  margin-top: 150px;
}

ul {
  padding: 20px !important;
}

.post-content {
  padding: 4em;
}

@media (max-width: 940px) {
  .post-body {
    border: none;
    margin-top: 20px;
  }
  .post-content {
    padding: 0;
  }
}
</style>