<!-- "Broneeri kohtumine" vorm -->
<template>
  <v-btn class="mt-5" color="eraklient" @click.stop.prevent @click="form = true">{{ $t('book') }}</v-btn>
  <v-dialog v-model="form" max-width="500" :fullscreen="xs" transition="none">
    <v-card>
      <v-toolbar color="white" :title="$t('book')">
        <v-btn icon="mdi-close" @click="form = false; handleReset()"></v-btn>
      </v-toolbar>
      <form class="pa-5" @submit.prevent="submit">
        <v-text-field
            variant="outlined"
            v-model="name.value.value"
            :error-messages="name.errorMessage.value"
            :label="$t('nimi')"
        ></v-text-field>
        <v-text-field
            variant="outlined"
            v-model="phone.value.value"
            :error-messages="phone.errorMessage.value"
            :label="$t('tel')"
        ></v-text-field>
        <v-text-field
            class="pb-3"
            variant="outlined"
            v-model="email.value.value"
            :error-messages="email.errorMessage.value"
            :label="$t('email')"
        ></v-text-field>
        <v-select
            variant="outlined"
            menu-icon="mdi-chevron-down"
            v-model="select_city.value.value"
            :items="cities"
            :error-messages="select_city.errorMessage.value"
            :label="$t('asukoht')"
        ></v-select>
        <v-select
            variant="outlined"
            menu-icon="mdi-chevron-down"
            v-model="select.value.value"
            :items="topics"
            :error-messages="select.errorMessage.value"
            :label="$t('teema')"
        ></v-select>
        <v-textarea
            variant="outlined"
            v-model="message.value.value"
            :error-messages="message.errorMessage.value"
            :label="$t('sonum')"
        ></v-textarea>
        <input type="text" name="honeypot" id="honeypot" style="display: none;" v-model="honeypot.value.value">
        <v-btn color="eraklient" class="d-flex w-100" type="submit">{{ $t('saada') }}</v-btn>
      </form>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {useField, useForm} from "vee-validate";
import {ref,computed} from "vue";
import {toast} from "vue3-toastify";
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'
const { xs } = useDisplay()
const { t, locale } = useI18n()
const form = ref(false);

const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    name (value) {
      if (value?.length > 1) return true

      return t('sisesta_nimi')
    },
    email (value) {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) return true

      return t('sisesta_email')
    },
    phone (value) {
      if (value?.length > 6 && /[0-9-]+/.test(value)) return true
      return t('sisesta_tel')
    },
    select_city (value) {
      if (value) return true
      return t('kohtumiskoht')
    },
    select (value) {
      if (value) return true
      return t('vali_teema')
    },
    message (value) {
      if (value) return true
      return t('sisesta_sonum')
    }
  },
})
const name = useField('name')
const phone = useField('phone')
const email = useField('email')
const select_city = useField('select_city')
const select = useField('select')
const message = useField('message')
const honeypot = useField('honeypot')

const cities = computed(() => {
  return [
    t('stuudio'),
    t('Tartu'),
    t('Tallinn'),
    t('Pärnu'),
    t('Rakvere'),
    t('Valga'),
    t('Muu')
  ];
})

const topics = computed(() => {
  return [
    t('toote_esitlus'),
    t('teenuse_tutvustus'),
    t('konsultatsioon')
  ];
})

const submit = handleSubmit(async values => {
  if (honeypot.value.value) {
    toast.success(t('mess_succ'));
    handleReset();
    form.value = false;
    return
  }
  try {
    const newValues = {
      ...values,
      subject: "Kohtumise broneering"
    };
    const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newValues)
    });

    if (response.ok) {
      toast.success(t('book_succ'));
      form.value = false;
      handleReset();
    }
    else {
      toast.error(t('book_err'));
    }
  }
  catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
})
</script>

<style scoped>

</style>