import { createRouter, createWebHistory } from 'vue-router'
import AboutView from '../views/Ettevottest/AboutView.vue'
import ContactView from '../views/ContactView.vue'
import PostView from "@/views/News/PostView";
import EditPost from "@/views/News/EditPost";
import AddPost from "@/views/News/AddPost";
import ProductView from "@/views/Products/ProductView";
import EditProduct from "@/views/Products/EditProduct";
import AddProduct from "@/views/Products/AddProduct";
import ViewProduct from "@/views/Products/ViewProduct";
import ServiceView from "@/views/Services/ServiceView";
import HuviringidView from "@/views/Services/Huviringid/HuviringidView";
import Cart from "@/views/Products/Cart";
import SignUp from "@/views/Admin/SignUp";
import LogIn from "@/views/Admin/LogIn";
import auth from "../auth";
import ViewPost from "@/views/News/ViewPost";
import FounderView from "@/views/Ettevottest/FounderView";
import ProductInfoView from "@/views/Products/ProductInfoView";
import EventsView from "@/views/Services/Events/UritusedView";
import HomeView from "@/views/Home/HomeView";
import AddFeedback from "@/views/Home/AddFeedback";
import EditFeedback from "@/views/Home/EditFeedback";
import LohnastusedView from "@/views/Services/Lohnastused/LohnastusedView";
import MeeskonnadView from "@/views/Services/Meeskonnad/MeeskonnadView";
import AddLohnastus from "@/views/Services/Lohnastused/AddLohnastus";
import AddMeeskond from "@/views/Services/Meeskonnad/AddMeeskond";
import AddUritus from "@/views/Services/Events/AddUritus";
import EditUritus from "@/views/Services/Events/EditUritus";
import AddHuviring from "@/views/Services/Huviringid/AddHuviring";
import EditHuviring from "@/views/Services/Huviringid/EditHuviring";
import EditLohnastus from "@/views/Services/Lohnastused/EditLohnastus";
import EditMeeskond from "@/views/Services/Meeskonnad/EditMeeskond";
import EditProductInfo from "@/views/Products/EditProductInfo";
import EditAbout from "@/views/Ettevottest/EditAbout";
import EditFounder from "@/views/Ettevottest/EditFounder";
import EditEraklient from "@/views/Services/EditEraklient";
import EditAriklient from "@/views/Services/EditAriklient";
import AddEditCategory from "@/views/Products/AddEditCategory";
import EditInfo from "@/views/Services/Events/EditInfo";
import EditMeeskondInfo from "@/views/Services/Meeskonnad/EditMeeskondInfo";
import Privacy from "@/views/Admin/Privacy";
import NinatrennView from "@/views/Products/Ninatrenn/NinatrennView.vue";
import EditNinatrenn from "@/views/Products/Ninatrenn/EditNinatrenn.vue";
import EditNinatrennInfo from "@/views/Products/Ninatrenn/EditNinatrennInfo.vue";
import KinkekaartView from "@/views/Products/Kinkekaardid/KinkekaartView.vue";
import EditKinkekaart from "@/views/Products/Kinkekaardid/EditKinkekaart.vue";
import EditKinkekaartInfo from "@/views/Products/Kinkekaardid/EditKinkekaartInfo.vue";
import AddKinkekaart from "@/views/Products/Kinkekaardid/AddKinkekaart.vue";
import EditLohnastusInfo from "@/views/Services/Lohnastused/EditLohnastusInfo.vue";

const routes = [
  {
    path: "/",
    name: "Avaleht",
    children: [
      {
        path: "",
        name: "Avaleht",
        component: HomeView,
      },
      {
        path: "/addfeedback",
        name: "Uus tagasiside",
        component: AddFeedback,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
      {
        path: "/editfeedback/:id",
        name: "Muuda tagasisidet",
        component: EditFeedback,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
    ]
  },
  {
    path: "/privaatsuspoliitika",
    name: "Privaatsuspoliitika",
    component: Privacy,
  },
  {
    path: "/asutajast",
    name: "Asutajast",
    children: [
      {
        path: "",
        name: "Asutajast",
        component: FounderView
      },
      {
        path: "editfounder",
        name: "Muuda asutajast",
        component: EditFounder,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          console.log(authResult);
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "/kaubamark",
    name: "Kaubamärk",
    children: [
       {
        path: "",
        name: "Kaubamärk",
        component: AboutView
      },
      {
        path: "editabout",
        name: "Muuda kaubamärki",
        component: EditAbout,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: ContactView,
  },
  {
    path: "/uudised",
    name: "Uudised",
    children: [
      {
          path: "",
          name: "Uudised",
          component: PostView,
      },
      {
        path: ":id",
        name: "Postitus",
        component: ViewPost,
        meta: { dynamicName: true },
      },
      {
        path: "editpost/:id",
        name: "Muuda uudist",
        component: EditPost,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
      {
        path: "addpost",
        name: "Uus uudis",
        component: AddPost,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
    ]
  },
  {
    path: "/tooteinfo",
    name: "Tooteinfo",
    children : [
      {
        path: "",
        name: "Tooteinfo",
        component: ProductInfoView,
      },
      {
        path: "editinfo",
        name: "Muuda tooteinfot",
        component: EditProductInfo,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "/tooted",
    name: "Tooted",
    children: [
      {
        path: "",
        name: "Tooted",
        component: ProductView
      },
      {
        path: "addeditcategory",
        name: "Lisa/Muuda",
        component: AddEditCategory,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
      {
        path: ":category",
        name: "ToodeteKategooriad",
        props: true,
        meta: { dynamicName: true },
        children: [
          {
            path: "",
            name: "ToodeteKategooriad",
            component: ProductView,
            props: true,
            meta: { dynamicName: true },
          },
          {
            path: ":id",
            name: "Product",
            component: ViewProduct,
            meta: { dynamicName: true },
          },
          {
            path: "editproduct/:id",
            name: "EditProduct",
            component: EditProduct,
            meta: { dynamicName: true },
            props: true,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
        ]
      },
      {
        path: "ostukorv",
        name: "ostukorv",
        component: Cart
      },
      {
        path: "/addproduct",
        name: "Uus toode",
        component: AddProduct,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
    ]
  },
  {
    path: "/teenused",
    name: "Teenused",
    children: [
      {
        path: "",
        name: "Teenused",
        component: ServiceView,
      },
      {
        path: "editeraklient",
        name: "Muuda eraklienti",
        component: EditEraklient,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
      {
        path: "editariklient",
        name: "Muuda äriklienti",
        component: EditAriklient,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
      {
        path: "uritused",
        name: "Ürituste programmid",
        children: [
          {
            path: "",
            name: "Ürituste programmid",
            component: EventsView
          },
          {
            path: "adduritus",
            name: "Uus üritus",
            component: AddUritus,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
          {
            path: "edituritus/:id",
            name: "Muuda üritust",
            component: EditUritus,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
          {
            path: "editinfo",
            name: "Muuda infot",
            component: EditInfo,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
        ]
      },
      {
        path: "huviringid",
        name: "Huviringid",
        children: [
          {
            path: "",
            name: "Huviringid",
            component: HuviringidView,
          },
          {
            path: "addhuviring",
            name: "Uus huviring",
            component: AddHuviring,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
          {
            path: "edithuviring/:id",
            name: "Muuda huviringi",
            component: EditHuviring,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
        ]
      },
      {
        path: "lohnastuslahendused",
        name: "Lõhnastuslahendused",
        children: [
          {
            path: "",
            name: "Lõhnastuslahendused",
            component: LohnastusedView,
          },
          {
            path: "addlohnastus",
            name: "Uus lõhnastuslahendus",
            component: AddLohnastus,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
          {
            path: "editlohnastus/:id",
            name: "Muuda lõhnastuslahendust",
            component: EditLohnastus,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
          {
            path: "editlohnastusinfo",
            name: "Muuda lõhnastuse infot",
            component: EditLohnastusInfo,
            beforeEnter: async (to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          }
        ]
      },
      {
        path: "meeskonnakoolitused",
        name: "Meeskonnakoolitused",
        children: [
          {
            path: "",
            name: "Meeskonnakoolitused",
            component: MeeskonnadView,
          },
          {
            path: "addmeeskond",
            name: "Uus meeskonnakoolitus",
            component: AddMeeskond,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
          {
            path: "editmeeskond/:id",
            name: "Muuda meeskonnakoolitust",
            component: EditMeeskond,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          },
          {
            path: "editmeeskondinfo",
            name: "Muuda meeskondade infot",
            component: EditMeeskondInfo,
            beforeEnter: async(to, from, next) => {
              let authResult = await auth.authenticated();
              if (!authResult) {
                next('/')
              } else {
                next();
              }
            }
          }
        ]
      }
    ]
  },
  {
    path: "/ninatrenn",
    name: "Ninatrenn",
    children: [
      {
        path: "",
        name: "Ninatrenn",
        component: NinatrennView
      },
      {
        path: "editninatrenn",
        name: "Muuda ninatrenni",
        component: EditNinatrenn,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
      {
        path: "editninatrenninfo",
        name: "Muuda ninatrenni infot",
        component: EditNinatrennInfo,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "/kinkekaardid",
    name: "Kinkekaardid",
    children: [
      {
        path: "",
        name: "Kinkekaardid",
        component: KinkekaartView
      },
      {
        path: "addkinkekaart",
        name: "Uus kinkekaart",
        component: AddKinkekaart,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
      {
        path: "editkinkekaart/:id",
        name: "Muuda kinkekaarti",
        component: EditKinkekaart,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      },
      {
        path: "editkinkekaartinfo",
        name: "Muuda kinkekaardi infot",
        component: EditKinkekaartInfo,
        beforeEnter: async(to, from, next) => {
          let authResult = await auth.authenticated();
          if (!authResult) {
            next('/')
          } else {
            next();
          }
        }
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    name: "Login",
    component: HomeView
  },
  {
    path: "/admin",
    name: "LogIn",
    component: LogIn
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp
  }
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ top: 0 });
        }
      }, 400);
    });
  }
})

export default router
