<!-- Vaheleht "Asutajast" -->
<template>
  <div class="page-properties main-content">
    <PageHeader>
      <h1>{{ $t('asutajast') }}</h1>
    </PageHeader>
    <a v-if="isLoggedIn" :href="'/asutajast/editfounder/'">
      <i class="fa fa-cog"></i>
    </a>
    <div class="tiptap tiptap-editor" v-html=getBody(info)></div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
export default {
  name: "FounderView",
  components: {PageHeader},
  data () {
    return {
      info: []
    }
  },
  methods: {
    fetchInfo() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/texts/2`)
          .then((response) => response.json())
          .then((data) => (this.info = data))
          .catch((err) => console.log(err.message));
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    }
  },
  mounted() {
    this.fetchInfo();
    console.log("mounted");
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  }
}
</script>

<style scoped>
a:hover {
  text-decoration: underline;
}

@media (max-width: 820px) {
  .main-content {
    padding: 1em;
  }
}
</style>