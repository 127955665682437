<!-- Korvi lisamise teate komponent -->
<template>
  <v-btn class="cart-btn" color="eraklient" @click.prevent="clicked = true">{{ $t('lisa_korvi') }}</v-btn>
  <v-dialog v-model="clicked" width="auto" transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar color="white" :title="$t('kinnitus')">
        <v-btn icon="mdi-close" @click="clicked = false"></v-btn>
      </v-toolbar>
      <v-card-text class="pa-4">
        <span class="text-h6 font-weight-bold">{{ $t('kinnitus_conf') }}</span>
        <div> {{ getHeader(product) }} </div>
      </v-card-text>
      <v-divider></v-divider>
      <div class="d-flex flex-column  pa-4">
        <span>{{ $t('valige_kogus') }}:</span>
        <div class="d-flex">
          <v-btn color="eraklient" @click="decreaseQuantity" icon="mdi-minus" size="30px"><v-icon size="20px"></v-icon></v-btn>
          <div class="item-qty">
            <input v-model="quantity" variant="outlined">
          </div>
          <v-btn color="eraklient" @click="increaseQuantity" icon="mdi-plus" size="30px"><v-icon size="20px"></v-icon></v-btn>
        </div>
      </div>
      <div class="pa-4">
        <v-btn class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="addToCart(product)" @click="clicked = false; showToastSuccess($t('lisatud_korvi'))">{{ $t('lisa_korvi') }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {toast} from "vue3-toastify";

export default {
  name: "AddToCart",
  props: ['product'],
  data() {
    return {
      clicked: false,
      quantity: 1,
    };
  },
  methods: {
    increaseQuantity() {
      this.quantity++;
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    addToCart(product) {
      this.$store.dispatch('addToCart', { product: product, quantity: this.quantity });
      this.quantity = 1;
    },
    showToastSuccess(value) {
      toast.success(value, {
        autoClose: 3000
      })
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getCat(content) {
      let id = content.category_id;
      let category = this.categories.find(cat => cat.id === id);
      if (category) return (this.$i18n.locale === 'en' && category.name_en !== null) ? category.name_en : (this.$i18n.locale === 'ru' && category.name_ru !== null) ? category.name_ru : category.name;
    },
    getLinkCat(content) {
      return this.$store.getters.getLinkCat(content);
    }
  }
}
</script>

<style scoped>
.cart-btn {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.prod-item:hover .cart-btn {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1000px) {
  .cart-btn {
    visibility: visible;
    opacity: 1;
    margin: 20px 0;
  }
}
</style>