<template>
  <div class="page-properties px-5">
    <page-header>
      <h1>{{$t('ninatrenn')}}</h1>
    </page-header>
    <a v-if="isLoggedIn" href="/ninatrenn/editninatrenninfo">
      <i class="fa fa-cog"></i>
    </a>
    <simple-quote class="pb-5"><div class="tiptap tiptap-editor" v-html=getBody(info)></div></simple-quote>
    <a v-if="isLoggedIn" :href="'/ninatrenn/editninatrenn'">
      <i class="fa fa-cog"></i>
    </a>
    <div class="tiptap tiptap-editor py-5" v-html=getBody(ninatrenn)></div>
    <div class="d-flex"><ask-lohnastus :service="content[0]"></ask-lohnastus></div>
  </div>
</template>

<script>
import SimpleQuote from "@/components/SimpleQuote.vue";
import PageHeader from "@/components/PageHeader.vue";
import ScrollSlider from "@/components/ScrollSlider.vue";
import AskLohnastus from "@/components/AskLohnastus.vue";

export default{
  name: "NinatrennView",
  components: {AskLohnastus, ScrollSlider, PageHeader, SimpleQuote},
  data() {
    return {
      isLoggedIn: false,
      info: [],
      ninatrenn: [],
      content: [
        {
          title: this.$t('ninatrenn')
        }
      ]
    };
  },
  methods: {
    fetchInfo() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/texts/6`)
          .then((response) => response.json())
          .then((data) => (this.info = data))
          .catch((err) => console.log(err.message));
    },
    fetchNinatrenn() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/texts/7`)
          .then((response) => response.json())
          .then((data) => (this.ninatrenn = data))
          .catch((err) => console.log(err.message));
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  },
  mounted() {
    this.fetchInfo();
    this.fetchNinatrenn();
    console.log("mounted");
  }
}
</script>

<style scoped>

</style>