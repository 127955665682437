<template>
  <div id="app">
    <main-header></main-header>
    <div class="page mb-10">
      <router-view/>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";
import flagEstonia from '@/assets/images/flag-estonia.svg';
import { useHead } from '@vueuse/head'
import {computed, reactive} from "vue";
import {useI18n} from "vue-i18n";

export default {
  name: 'App',
  setup() {
    const { t } = useI18n()
    const siteData = reactive({
      title: computed(() => `Lõhnakas OÜ`),
      description: computed(() => `${t('home_desc')}`)
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
    })
  },
  components: {MainFooter, MainHeader},
  created() {
    const savedLocale = localStorage.getItem('locale');
    const savedLang = localStorage.getItem('selectedLang');
    if (savedLocale) {
      this.$i18n.locale = savedLocale;
    }
    if (savedLang) {
      this.$i18n.selectedLang = savedLang;
    }
    else if (!savedLang) {
      this.$i18n.selectedLang = flagEstonia;
    }
  },
  mounted() {
    document.documentElement.setAttribute('lang', this.$i18n.locale);
  },
  watch: {
    '$i18n.locale': function(newLocale) {
      document.documentElement.setAttribute('lang', newLocale);
      localStorage.setItem('locale', newLocale);
    },
    '$i18n.selectedLang': function(newLang) {
      localStorage.setItem('selectedLang', newLang);
    }
  }
}
</script>

<style>
.page {
  height: 100%;
  min-height: 80vh;
  margin: 80px auto;
  text-align: center;
}

@media (max-width: 1200px) {
  .page {
    margin: 0 auto;
  }
}
</style>
