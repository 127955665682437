import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import Paginate from 'vuejs-paginate';
import './assets/css/main.css';
import { createI18n } from 'vue-i18n'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'remixicon/fonts/remixicon.css'
import 'vue3-toastify/dist/index.css'
import { MotionPlugin } from '@vueuse/motion'
import { createHead } from '@vueuse/head'
import ET from './locales/et.json'
import EN from './locales/en.json'
import RU from './locales/ru.json'

const app = createApp(App)
const myCustomLightTheme = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        eraklient: '#3b71f7',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
}

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
        },
    }
})

const i18n = createI18n({
    legacy: false,
    locale: store.state.locale,
    selectedLang: store.state.selectedLang,
    messages: {
        et: ET,
        en: EN,
        ru: RU
    }
})

const head = createHead()

app.component('paginate', Paginate).use(router).use(store).use(vuetify).use(i18n).use(MotionPlugin).use(head).mount('#app')