<!-- Lehe ülaosa, mis sisaldab alati jäljemenüüd -->
<template>
  <div class="header py-5">
    <div class="header-item"><slot></slot></div>
    <div class="breadcrumb-item"><breadcrumbs class="breadcrumb"></breadcrumbs></div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
export default {
  name: "PageHeader",
  components: {Breadcrumbs}
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.breadcrumb-item {
  white-space:nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  font-size: 15px;
  line-height: 14px;
  list-style: none;
  width: 100%;
  margin: 0;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
  & {
    -ms-overflow-style: none;
  }
  li {
    &:after {
      padding: 0 0.25em 0 0.5em;
      color: #999;
    }
    &.last:before, &.last:after {
      content: '';
    }
  }
}

@media (max-width: 1000px) {
  .header {
    flex-direction: column;
  }
  .breadcrumb {
    display: block;
  }
  .header-item {
    display: unset;
  }
  .breadcrumb-item {
    padding: 20px;
  }
}
</style>