<!-- "Saada päring" vorm -->
<template>
  <v-btn color="eraklient" class="d-flex w-100" @click.stop.prevent @click="form = true">{{ $t('paring') }}</v-btn>
  <v-dialog v-model="form" max-width="500" :fullscreen="xs" transition="none">
    <v-card>
      <v-toolbar color="white" :title="$t('paring')">
        <v-btn icon="mdi-close" @click="form = false; handleReset()"></v-btn>
      </v-toolbar>
      <form class="pa-5" @submit.prevent="submit">
        <v-text-field
            variant="outlined"
            v-model="name.value.value"
            :error-messages="name.errorMessage.value"
            :label="$t('nimi')"
        ></v-text-field>
        <v-text-field
            variant="outlined"
            v-model="phone.value.value"
            :error-messages="phone.errorMessage.value"
            :label="$t('tel')"
        ></v-text-field>
        <v-text-field
            variant="outlined"
            v-model="email.value.value"
            :error-messages="email.errorMessage.value"
            :label="$t('email')"
        ></v-text-field>
        <v-select
            variant="outlined"
            menu-icon="mdi-chevron-down"
            v-model="select.value.value"
            :items="items"
            :error-messages="select.errorMessage.value"
            :label="$t('vali_teenus')"
        ></v-select>
        <v-textarea
            variant="outlined"
            v-model="message.value.value"
            :error-messages="message.errorMessage.value"
            :label="$t('sonum')"
        ></v-textarea>
        <input type="text" name="honeypot" id="honeypot" style="display: none;" v-model="honeypot.value.value">
        <v-btn color="eraklient" class="d-flex w-100" type="submit" value="Submit">{{ $t('saada') }}</v-btn>
      </form>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {useField, useForm} from "vee-validate";
import {ref,computed} from "vue";
import {toast} from "vue3-toastify";
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'
const { xs } = useDisplay()
const { t, locale } = useI18n()
const form = ref(false);

const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    name (value) {
      if (value?.length > 1) return true
      return t('sisesta_nimi')
    },
    email (value) {
      if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)) return true
      return t('sisesta_email')
    },
    phone (value) {
      if (value?.length > 6 && /[0-9-]+/.test(value)) return true
      return t('sisesta_tel')
    },
    select (value) {
      if (value) return true
      return t('sisesta_teenus')
    },
    message (value) {
      if (value) return true
      return t('sisesta_sonum')
    }
  },
})
const name = useField('name')
const phone = useField('phone')
const email = useField('email')
const select = useField('select')
const message = useField('message')
const honeypot = useField('honeypot')

const items = computed(() => {
  return [
    t("lõhnaturundus"),
    t("lõhnakujundus"),
    t("lõhnabränding"),
    t("lõhnateambuilding"),
    t("signatuurlõhnad"),
    t("ärikingitused"),
    t("lõhnacatering"),
    t("lõhnadetektiiv"),
    t("lõhnaportree_loomine"),
    t("lõhnakunst"),
    t("ninatrenn"),
    t("meeleline_integratsioon"),
    t("pisikene_ime"),
    t("lõhnaloto"),
    t("praktiline_seminar"),
    t("huviringid"),
    t("lõhnakoolitus_grupile"),
    t("personaalne_lõhnakoolitus"),
    t("lõhnakonsultatsioon"),
    t("parfüümi_loomine"),
    t("kinkekaardid"),
    t("tooted"),
    t("100%_looduslikud_õlid"),
    t("kodulõhnastajad"),
    t("autolõhnastajad"),
    t("Muu")
  ];
})

const submit = handleSubmit(async values => {
  if (honeypot.value.value) {
    toast.success(t('mess_succ'));
    handleReset();
    form.value = false;
    return
  }
  try {
    const newValues = {
      ...values,
      subject: "Päring"
    };
    const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newValues)
    });

    const data = await response.text();
    console.log(data);
    if (response.ok) {
      toast.success(t('inq_succ'));
      handleReset();
      form.value = false;
    }
    else {
      toast.error(t('inq_err'));
    }
  }
  catch (error) {
    console.error('There has been a problem with your fetch operation:', error);
  }
})
</script>

<style scoped>

</style>