// Pärit kursuse Veebirakenduse loomine (LTAT.05.004) materjalidest
export default {
    user: { authenticated: false },
    authenticated: async function() {
        await fetch(`${process.env.VUE_APP_SERVER_URL}/auth/authenticate`, {
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                this.user.authenticated = data.authenticated;
                console.log(data);
            })
            .catch((e) => {
                console.log(e);
                console.log("error logout");
            });
        return this.user.authenticated;
    }
}