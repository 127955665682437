<!--
Tekstiredaktor, mis kasutab Tiptap pluginat
Inspiratsioon: https://www.youtube.com/watch?v=qmthtmQ6pP4&t=1s
-->
<template>
  <div class="menu-buttons" v-if="editor">
    <button title="Image URL" @click.prevent.stop="addImage">
      <i class="ri-image-line"></i>
    </button>
    <button title="YouTube video" id="add" @click.prevent.stop="addVideo">
      <i class="ri-video-line"></i>
    </button>
    <button title="Set link" @click.prevent.stop="setLink" :class="{ 'is-active': editor.isActive('link') }">
      <i class="ri-link"></i>
    </button>
    <button title="Unset link" @click.prevent.stop="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
      <i class="ri-link-unlink"></i>
    </button>
    <button title="Blockquote" @click.prevent.stop="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
      <i class="ri-double-quotes-l"></i>
    </button>
    <button title="Bold" @click.prevent.stop="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
      <i class="ri-bold"></i>
    </button>
    <button title="Italic" @click.prevent.stop="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
      <i class="ri-italic"></i>
    </button>
    <button title="Strike" @click.prevent.stop="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
      <i class="ri-strikethrough"></i>
    </button>
    <button title="Heading 1" @click.prevent.stop="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
      <i class="ri-h-1"></i>
    </button>
    <button title="Heading 2" @click.prevent.stop="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
      <i class="ri-h-2"></i>
    </button>
    <button title="Heading 3" @click.prevent.stop="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
      <i class="ri-h-3"></i>
    </button>
    <button title="Heading 4" @click.prevent.stop="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
      <i class="ri-h-4"></i>
    </button>
    <button title="Paragraph" @click.prevent.stop="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
      <i class="ri-paragraph"></i>
    </button>
    <button title="Bullet list" @click.prevent.stop="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList', { padding: '20px' }) }">
      <i class="ri-list-unordered"></i>
    </button>
    <button title="Ordered list" @click.prevent.stop="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
      <i class="ri-list-ordered"></i>
    </button>
    <button title="Highlight" @click.prevent.stop="editor.chain().focus().toggleHighlight().run()" :class="{ 'is-active': editor.isActive('highlight') }">
      <i class="ri-mark-pen-line"></i>
    </button>
    <button title="Align left" @click.prevent.stop="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
      <i class="ri-align-left"></i>
    </button>
    <button title="Align center" @click.prevent.stop="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
      <i class="ri-align-center"></i>
    </button>
    <button title="Align right" @click.prevent.stop="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
      <i class="ri-align-right"></i>
    </button>
    <button title="Align justify" @click.prevent.stop="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
      <i class="ri-align-justify"></i>
    </button>
    <button title="Hard break" @click.prevent.stop="editor.chain().focus().setHardBreak().run()">
      <i class="ri-text-wrap"></i>
    </button>
    <button title="Undo" @click.prevent.stop="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()">
      <i class="ri-arrow-go-back-line"></i>
    </button>
    <button title="Redo" @click.prevent.stop="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()">
      <i class="ri-arrow-go-forward-line"></i>
    </button>
  </div>
  <editor-content class="edits" :editor="editor" />
</template>

<script setup>
import { useEditor, EditorContent } from '@tiptap/vue-3'
import Blockquote from '@tiptap/extension-blockquote'
import Highlight from '@tiptap/extension-highlight'
import TextAlign from '@tiptap/extension-text-align'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import { SmilieReplacer } from '@/assets/SmileReplacer.ts'
import Typography from '@tiptap/extension-typography'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Youtube from '@tiptap/extension-youtube'
import Link from '@tiptap/extension-link'
import {onBeforeUnmount, watch} from "vue";
import HardBreak from '@tiptap/extension-hard-break'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  }
});

const emit = defineEmits(['update:modelValue']);

const editor = useEditor({
  content: props.modelValue,
  extensions: [
      StarterKit,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Highlight,
      Image,
      Typography,
      SmilieReplacer,
      Youtube.configure({
        controls: false,
      }),
      Link.configure({
        openOnClick: true,
      }),
      Blockquote.configure({
        HTMLAttributes: {
          class: 'my-custom-svg',
        },
      }),
    HardBreak.configure({
      keepMarks: false,
    })
  ],
  onUpdate: () => {
    emit('update:modelValue', editor.value.getHTML());
  }
});


watch(
    () => props.modelValue,
    value => {
      const isSame = editor.value.getHTML() === value;
      if (isSame) {
        return;
      }
      editor.value.commands.setContent(value, false);
    },
);

const addImage = () => {
  const url = window.prompt('URL')

  if (url) {
    editor.value.chain().focus().setImage({ src: url }).run()
  }
};

const addVideo = () => {
  const url = prompt('Enter YouTube URL')

  if (url) {
    editor.value.commands.setYoutubeVideo({
      src: url,
      width: "100%",
    })
  }
};

const setLink = () => {
  const previousUrl = editor.value.getAttributes('link').href
  const url = window.prompt('URL', previousUrl)

  if (url === null) {
    return
  }

  if (url === '') {
    editor.value
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run()

    return
  }

  editor.value
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run()
};
</script>


<style lang="scss">
.tiptap {
  margin-top: 1rem;
  padding: 20px;
  border: 1px solid black;
  border-radius: 0.3rem;
  cursor: text;

  > * + * {
    margin-top: 0.75em;
  }

  a {
    color: #0041EB;
    font-weight: bold;
  }

  .my-custom-svg::before {
    content: url('@/assets/images/simplequote.svg');
  }

  blockquote {
    display: flex;
    align-items: center;
    background: #eeeeee;
    text-align: left;
    justify-content: left;
    font-style: italic;
    border-radius: 8px;
    padding: 15px;
  }

  blockquote p {
    padding-left: 15px;
  }

  a:hover {
    text-decoration: underline;
  }

  ul,
  ol {
    padding: 0 1.5rem;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1em;
  }

  h1,
  h2,
  h3,
  h4
  {
    line-height: 1.2;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  mark {
    background-color: #FAF594;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

  code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
  }

  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

.menu-buttons button {
  font-size: inherit;
  font-family: inherit;
  color: #000;
  margin: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.1rem 0.4rem;
  background: white;
  accent-color: black;
}

.menu-buttons .is-active {
  background: black;
  color: #fff;
}
</style>