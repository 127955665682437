<!-- Uudise muutmise vaheleht -->
<template>
  <page-header class="px-10">
    <h1>Muuda uudist</h1>
  </page-header>
  <v-sheet class="post-body d-flex justify-center pa-10 ga-10">
    <div class="products">
      <v-form ref="form">
        <v-text-field
            v-model=post.image
            label="Pilt"
            variant="outlined"
        ></v-text-field>
        <v-tabs v-model="tab" bg-color="eraklient">
          <v-tab value="one" @click="changeLocale('et')">EST</v-tab>
          <v-tab value="two" @click="changeLocale('en')">ENG</v-tab>
          <v-tab value="three" @click="changeLocale('ru')">RUS</v-tab>
        </v-tabs>
        <v-window class="py-5" v-model="tab">
          <v-window-item value="one">
            <v-text-field
                v-model=post.header
                label="Pealkiri"
                :rules="rules"
                :counter="200"
                variant="outlined"
            ></v-text-field>
            <tiptap v-model="post.body"/>
          </v-window-item>
          <v-window-item value="two">
            <v-text-field
                v-model=post.header_en
                label="Pealkiri"
                :counter="200"
                variant="outlined"
            ></v-text-field>
            <tiptap v-model="post.body_en"/>
          </v-window-item>
          <v-window-item value="three">
            <v-text-field
                v-model=post.header_ru
                label="Pealkiri"
                :counter="200"
                variant="outlined"
            ></v-text-field>
            <tiptap v-model="post.body_ru"/>
          </v-window-item>
        </v-window>
      </v-form>
      <v-btn color="success" @click="updatePost" class="mt-2">Uuenda</v-btn>
      <alert>
        <template #first-btn>Kustuta</template>
        <div class="text-h5 justify-center text-center">Kas oled kindel, et soovid postituse kustutada?</div>
        <template #confirmation-btn>
          <v-btn class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="deletePost">Jah</v-btn>
        </template>
      </alert>
    </div>
    <div class="products">
      <v-sheet class="post-container pa-16">
        <v-list-item-subtitle>{{ this.getDate(post.date) }}</v-list-item-subtitle>
        <v-list-item-title class="text-h4 font-weight-bold py-6 text-center">{{ getHeader(post) }}</v-list-item-title>
        <img v-if="post.image" alt="" :src="post.image" />
        <img v-else alt="lõhnakas logo" src="../../assets/images/lohnakas.webp" />
        <div class="tiptap tiptap-editor" v-html=getBody(post)></div>
      </v-sheet>
    </div>
  </v-sheet>
</template>

<script>
import Tiptap from '@/components/Tiptap.vue'
import PageHeader from "@/components/PageHeader";
import Alert from "@/components/Alert";
import {mapGetters} from "vuex";

export default {
  name: "EditPost",
  components: {Alert, PageHeader, Tiptap},
  data() {
    return {
      valid: false,
      tab: null,
      post: [],
      rules: [
        value => {
          if (value) return true

          return 'Ei saa olla tühi.'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'formatDate'
    ]),
  },
  methods: {
    fetchAPost(id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/posts/${id}`)
          .then((response) => response.json())
          .then((data) => (this.post = data))
          .catch((err) => console.log(err.message));
    },
    async updatePost() {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/posts/${this.post.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.post),
        })
            .then((response) => {
              console.log(response.data);
              this.$router.push(`/uudised/${this.post.id}`);
            })
            .catch((e) => {
              console.log(e);
            });
      }
    },

    deletePost() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/posts/${this.post.id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      })
          .then((response) => {
            console.log(response.data);
            location.assign("/uudised");
          })
          .catch((e) => {
            console.log(e);
          });
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    },
    changeLocale(value) {
      this.$i18n.locale = value;
    },
    getDate(date) {
      return this.formatDate(date);
    }
  },
  mounted() {
    this.fetchAPost(this.$route.params.id);
  },
}
</script>

<style scoped>
.v-list-item-title {
  white-space: break-spaces;
  line-height: 1em;
}

.post-container {
  border-width: thin;
}
</style>