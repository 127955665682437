<template>
  <PageHeader class="px-10">
    <h1>Muuda meeskondade infot</h1>
  </PageHeader>
  <v-sheet class="post-body d-flex justify-center pa-10 ga-10">
    <div class="products">
      <v-form ref="form">
        <v-tabs v-model="tab" bg-color="eraklient">
          <v-tab value="one" @click="changeLocale('et')">EST</v-tab>
          <v-tab value="two" @click="changeLocale('en')">ENG</v-tab>
          <v-tab value="three" @click="changeLocale('ru')">RUS</v-tab>
        </v-tabs>
        <v-window class="py-5" v-model="tab">
          <v-window-item value="one">
            <tiptap v-model="info.body"/>
          </v-window-item>
          <v-window-item value="two">
            <tiptap v-model="info.body_en"/>
          </v-window-item>
          <v-window-item value="three">
            <tiptap v-model="info.body_ru"/>
          </v-window-item>
        </v-window>
      </v-form>
      <v-btn color="success" @click="updateInfo" class="mt-2">Uuenda</v-btn>
    </div>
    <div class="products">
      <simple-quote><div class="tiptap tiptap-editor" v-html=getBody(info)></div></simple-quote>
    </div>
  </v-sheet>
</template>

<script>
import Tiptap from "@/components/Tiptap";
import PageHeader from "@/components/PageHeader";
import SimpleQuote from "@/components/SimpleQuote";

export default {
  name: "EditMeeskondInfo",
  components: {SimpleQuote, Tiptap, PageHeader},
  data () {
    return {
      tab: null,
      info: []
    }
  },
  methods: {
    fetchInfo() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/texts/5`)
          .then((response) => response.json())
          .then((data) => (this.info = data))
          .catch((err) => console.log(err.message));
    },
    async updateInfo () {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/texts/${this.info.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.info),
        })
            .then((response) => {
              console.log(response.data);
              this.$router.push("/teenused/meeskonnakoolitused");
            })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    },
    changeLocale(value) {
      this.$i18n.locale = value;
    },
  },
  mounted() {
    this.fetchInfo();
    console.log("mounted");
  },
}
</script>

<style scoped>

</style>