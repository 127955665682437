<template>
  <div class="main-content page-properties px-5">
    <page-header>
      <h1>{{$t('tutvu_uritus')}}</h1>
      <v-btn v-if="isLoggedIn" color="success" to="/teenused/uritused/adduritus">lisa +</v-btn>
    </page-header>
    <a v-if="isLoggedIn" href="/teenused/uritused/editinfo">
      <i class="fa fa-cog"></i>
    </a>
    <simple-quote><div class="tiptap tiptap-editor" v-html=getBody(info)></div></simple-quote>
    <scroll-slider :fetchData="fetchDataForEvents" :href="'/teenused/uritused/edituritus/'" service="uritused"></scroll-slider>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import ScrollSlider from "@/components/ScrollSlider";
import SimpleQuote from "@/components/SimpleQuote";
export default {
  name: "UritusedView",
  components: {SimpleQuote, ScrollSlider, PageHeader},
  data() {
    return {
      info: []
    };
  },
  methods: {
    fetchDataForEvents() {
      return fetch(`${process.env.VUE_APP_SERVER_URL}/api/services/uritused`)
          .then((response) => response.json())
          .catch((err) => console.log(err.message));
    },
    fetchInfo() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/texts/4`)
          .then((response) => response.json())
          .then((data) => (this.info = data))
          .catch((err) => console.log(err.message));
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  mounted() {
    this.fetchInfo();
    console.log("mounted");
  }
}
</script>

<style scoped>

</style>