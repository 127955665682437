<!-- Üksiku e-poe toote vaheleht -->
<template>
  <v-sheet class="page-properties default-padding">
    <page-header></page-header>
    <v-sheet class="mx-auto" v-if="isLoading">
      <v-skeleton-loader type="heading, image, paragraph"></v-skeleton-loader>
    </v-sheet>
    <v-sheet v-else class="d-flex content-body ga-5">
      <v-sheet class="d-flex w-100">
        <div class="d-flex align-center justify-center">
          <img v-if="product.image" :src="product.image" alt=""/>
          <img v-else class="pa-5" src="../../assets/images/lohnakas.png" alt="Lohnakas logo"/>
        </div>
      </v-sheet>
      <v-sheet class="rounded-lg order-summary" elevation="5">
        <div class="d-flex align-center flex-1-1 pa-5">
          <div class="d-flex text-left flex-column w-100">
            <h2>{{ getHeader(product) }}</h2>
            <a class="cat" :href="`/tooted/` + getLinkCat(product)" @click="redirectTo(product)"> {{ getCat(product) }} </a>
            <div class="tiptap tiptap-editor py-3" v-html=getBody(product)></div>
            <div class="d-flex product-price font-weight-bold">
              <p class="new-price pr-2" v-if="product.new_price"> {{ product.new_price }}€</p>
              <p class="old-price" v-if="product.new_price"> {{ product.price }}€</p>
              <p v-if="!product.new_price"> {{ product.price }}€</p>
            </div>
            <div class="d-flex align-center py-5">
              <v-btn color="eraklient" @click="decreaseQuantity" icon="mdi-minus" size="30px" aria-label="Decrease Quantity"><v-icon size="20px"></v-icon></v-btn>
              <div class="item-qty">
                <input :title="$t('valige_kogus')" v-model="quantity">
              </div>
              <v-btn color="eraklient" @click="increaseQuantity" icon="mdi-plus" size="30px" aria-label="Increase Quantity"><v-icon size="20px"></v-icon></v-btn>
            </div>
            <v-dialog v-model="valid" width="auto" transition="dialog-bottom-transition">
              <v-card>
                <v-toolbar color="white" :title="$t('kinnitus')">
                  <v-btn icon="mdi-close" @click="valid = false"></v-btn>
                </v-toolbar>
                <v-card-text class="pa-4">
                  <span class="text-h6 font-weight-bold">{{ $t('kinnitus_conf') }}</span>
                  <div> {{ getHeader(product) }} </div>
                </v-card-text>
                <v-divider></v-divider>
                <div class="pa-4">
                  <v-btn class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="addToCart" @click="valid = false; showToastSuccess($t('lisatud_korvi'))">{{ $t('lisa_korvi') }}</v-btn>
                </div>
              </v-card>
            </v-dialog>
            <v-btn class="cart-btn" color="eraklient" @click.stop.prevent aria-haspopup="true" :aria-expanded="valid" @click="valid = true">{{ $t('lisa_korvi') }}</v-btn>
          </div>
        </div>
      </v-sheet>
    </v-sheet>
  </v-sheet>
</template>


<script>
import PageHeader from "@/components/PageHeader";
import {toast} from "vue3-toastify";

export default {
  name: "ViewProduct",
  components: {PageHeader},
  data() {
    return {
      product: [],
      categories: this.$store.state.categories,
      quantity: 1,
      isLoading: true,
      valid: false
    };
  },
  methods: {
    fetchAProduct(id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/products/${id}`)
          .then((response) => response.json())
          .then((data) => {
            this.product = data;
            this.isLoading = false;
          })
          .catch((err) => console.log(err.message));
    },
    increaseQuantity() {
      this.quantity++;
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    addToCart() {
      this.$store.dispatch('addToCart', { product: this.product, quantity: this.quantity });
      this.quantity = 1;
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.description_en !== null) ? content.description_en : (this.$i18n.locale === 'ru' && content.description_ru !== null) ? content.description_ru : content.description;
    },
    getCat(content) {
      let id = content.category_id;
      let category = this.categories.find(cat => cat.id === id);
      if (category) return (this.$i18n.locale === 'en' && category.name_en !== null) ? category.name_en : (this.$i18n.locale === 'ru' && category.name_ru !== null) ? category.name_ru : category.name;
    },
    getLinkCat(content) {
      return this.$store.getters.getLinkCat(content);
    },
    redirectTo(content) {
      let linkCat = this.getLinkCat(content);
      let capitalizedLinkCat = linkCat.charAt(0).toUpperCase() + linkCat.slice(1);
      sessionStorage.setItem('selectedCategory', capitalizedLinkCat);
    },
    showToastSuccess(value) {
      toast.success(value, {
        autoClose: 3000
      })
    },
  },
  mounted() {
    this.fetchAProduct(this.$route.params.id);
  }
}
</script>

<style scoped>
@media (max-width: 820px) {
  .rounded-lg {
    border-radius: 0px !important;
  }
  .elevation-5 {
    box-shadow: none !important;
  }
  .pa-5 {
    padding-bottom: 20px !important;
  }
}
</style>