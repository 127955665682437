import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import auth from "../auth.js"

export default createStore({
  state: {
    cart: {},
    categories: [],
    items:[],
    isLoggedIn: localStorage.getItem('isLoggedIn') || false,
    showMessage: false,
    product: null,
    products: [],
    post: null,
    selectedLang: localStorage.getItem('selectedLang') || require('@/assets/images/flag-estonia.svg'),
    locale: localStorage.getItem('locale') || 'et'
  },
  mutations: {
    addToCart(state, { product, quantity }) {
      if (state.cart[product.id]) {
        state.cart[product.id].quantity += quantity;
      } else {
        state.cart[product.id] = { product, quantity };
      }
    },
    removeFromCart(state, productId) {
      delete state.cart[productId];
    },
    increaseQuantity(state, productId) {
      if (state.cart[productId]) {
        state.cart[productId].quantity = Number(state.cart[productId].quantity) + 1;
      }
    },
    decreaseQuantity(state, productId) {
      if (state.cart[productId]) {
        if (state.cart[productId].quantity > 1) {
          state.cart[productId].quantity -= 1;
        } else {
          this.commit('removeFromCart', productId);
        }
      }
    },
    deleteCart(state) {
      state.cart = {};
    },
    SET_LOGIN_STATUS(state, status) {
      if (status === false) localStorage.removeItem('isLoggedIn');
      else localStorage.setItem('isLoggedIn', status);
      state.isLoggedIn = status;
    },
    SET_TOAST_MESSAGE(state, message) {
      state.showMessage = message;
    },
    updateQuantity(state, { productId, quantity }) {
      if (state.cart[productId]) {
        state.cart[productId].quantity = quantity;
      }
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setProducts(state, products) {
      state.products = products;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setPost(state, post) {
      state.post = post;
    },
    setUser(state, user) {
      state.user = user;
    }
  },
  actions: {
    addToCart(context, { product, quantity }) {
      context.commit('addToCart', { product, quantity });
    },
    removeFromCart(context, productId) {
      context.commit('removeFromCart', productId);
    },
    async checkLoginStatus({ commit }) {
      try {
        const isAuthenticated = await auth.authenticated();
        commit('SET_LOGIN_STATUS', isAuthenticated);
      } catch (e) {
        console.log(e);
        console.log("error checking login status");
      }
    },
    fetchProducts({ commit }) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/products`)
          .then((response) => response.json())
          .then((data) => commit('setProducts', data))
          .catch((err) => console.log(err.message));
    },
    async fetchProduct({ commit }, id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/products/${id}`)
          .then((response) => response.json())
          .then((data) => commit('setProduct', data))
          .catch((err) => console.log(err.message));
    },
    async fetchPost({ commit }, id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/posts/${id}`)
          .then((response) => response.json())
          .then((data) => commit('setPost', data))
          .catch((err) => console.log(err.message));
    },
    fetchCategories({ commit }) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/categories`)
          .then((response) => response.json())
          .then((data) => commit('setCategories', data))
          .catch((err) => console.log(err.message));
    }
  },
  getters: {
    cartItemCount(state) {
      return Object.keys(state.cart).length;
    },
    cartTotal: state => {
      return Object.values(state.cart).reduce((total, item) => {
        const price = item.product.new_price || item.product.price;
        return total + (price * item.quantity);
      }, 0);
    },
    getProducts(state) {
      return state.products;
    },
    getProduct(state) {
      return state.product;
    },
    getUser(state) {
      return state.user;
    },
    getPost(state) {
      return state.post;
    },
    getLang(state) {
      return state.locale;
    },
    getSelectedLang(state) {
      return state.selectedLang;
    },
    getCategories(state) {
      return state.categories;
    },
    formatDate: (state) => (dateString) => {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
    getLinkCat: (state) => (content) => {
      let id = content.category_id;
      let category = state.categories.find(cat => cat.id === id);
      if (category) return category.name.toLowerCase();
    }
  },
  modules: {
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
