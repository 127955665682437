<!-- Idee: https://codepen.io/MAHESHBYL/pen/vYygPrm -->
<template>
  <footer>
    <div class="footer">
      <div class="row">
        <a aria-label="Link to facebook page" href="https://www.facebook.com/lohnakas" target=”_blank”><i class="fab fa-facebook-f"></i></a>
        <a aria-label="Link to instagram page" href="http://instagram.com/lohnakas" target=”_blank”><i class="fab fa-instagram"></i></a>
        <a aria-label="Link to tiktok page" href="https://www.tiktok.com/@lohnakas" target=”_blank”><i class="fab fa-tiktok"></i></a>
      </div>

      <div class="row">
        <ul>
          <li><router-link to="/kaubamark">{{ $t('ettevote') }}</router-link></li>
          <li><router-link to="/teenused">{{ $t('teenused') }}</router-link></li>
          <li><router-link to="/tooted">{{ $t('tooted') }}</router-link></li>
          <li><router-link to="/uudised">{{ $t('uudised') }}</router-link></li>
          <li><router-link to="/kontakt">{{ $t('kontakt') }}</router-link></li>
        </ul>
      </div>
      <div class="row"><router-link to="/privaatsuspoliitika">{{ $t('privaatsuspoliitika') }}</router-link></div>
      <div class="row">©2024 Lõhnakas OÜ</div>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  right: 0;
  left: 0;
  background:#000;
  padding:30px 0px;
  margin-top: auto;
  text-align: center;

}

.footer .row{
  width:100%;
  margin:1% 0%;
  padding:0.6% 0%;
  color:gray;
  font-size:0.8em;
}

.footer .row a{
  text-decoration:none;
  color:gray;
  transition:0.5s;
}

.footer .row a:hover{
  color:#fff;
}

.footer .row ul{
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.footer .row ul li{
  display: inline-block;
  padding:0px 10px;
}

.footer .row a i{
  font-size:2em;
  margin:0% 1%;
}

@media (max-width:1000px){
  .footer{
    padding:5%;
  }
  .footer .row ul li{
    display: block;
    margin:10px 0px;
    align-content: center;
  }
  .footer .row ul {
    display: block;
  }
  .footer .row a i{
    margin:0% 3%;
  }
}
</style>