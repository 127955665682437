<!-- Vaheleht "Tooteinfo" -->
<template>
  <v-sheet class="main-content page-properties px-5">
    <PageHeader>
      <h1>{{ $t('tooteinfo') }}</h1>
    </PageHeader>
    <a v-if="isLoggedIn" :href="'/tooteinfo/editinfo/'">
      <i class="fa fa-cog"></i>
    </a>
    <div class="tiptap tiptap-editor" v-html=getBody(info)></div>
  </v-sheet>
</template>

<script>
import PageHeader from "@/components/PageHeader";
export default {
  name: "ProductInfoView",
  components: {PageHeader},
  data () {
    return {
      info: []
    }
  },
  methods: {
    fetchInfo() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/texts/1`)
          .then((response) => response.json())
          .then((data) => (this.info = data))
          .catch((err) => console.log(err.message));
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    }
  },
  mounted() {
    this.fetchInfo();
    console.log("mounted");
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  }
}
</script>

<style scoped>

</style>