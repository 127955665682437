<!-- Vaheleht "E-pood"
Idee terve ostukorvi süsteemi loomiseks: https://www.youtube.com/watch?v=444bt-GNufc -->
<template>
  <div class="page-properties">
    <PageHeader>
      <h1>{{ $t('epood') }}</h1>
      <v-btn v-if="isLoggedIn" color="success" to="/addproduct">lisa +</v-btn>
    </PageHeader>
    <!-- Mobile sort options overlay -->
    <v-dialog v-model="dialog" fullscreen>
      <v-card>
        <v-toolbar style="background-color: white">
          <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
          <v-toolbar-title>{{ $t('sorteeri') }}</v-toolbar-title>
        </v-toolbar>
        <v-sheet class="pa-5">
          <v-select v-model="selectedCategory" :items="getCategories" item-title="value" item-value="key" :label="$t('filter')" menu-icon="mdi-chevron-down" variant="outlined" clearable=""></v-select>
          <v-select class="flex-0-0" v-model="sortOption" :items="sortOptions" item-title="text" :label="$t('sorteeri')" menu-icon="mdi-chevron-down" variant="outlined"></v-select>
          <v-select class="flex-0-0" v-model="perPage" :items="perPageOptions" :label="$t('tooteid')" menu-icon="mdi-chevron-down" variant="outlined"></v-select>
        </v-sheet>
      </v-card>
    </v-dialog>
    <div class="mobile-filters flex-column main-content">
      <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" variant="outlined" :label="$t('otsi')"></v-text-field>
      <div class="d-flex justify-space-between align-center">
        <div class="mobile-filters">{{ itemsPerPage }} {{ $t('kuvatud') }} {{ totalProducts }}<span v-if="this.$i18n.locale === 'et'">-st</span></div>
        <v-btn class="mobile-filters" prepend-icon="mdi-sort" variant="outlined" @click="dialog = true">{{ $t('sorteeri') }}</v-btn>
      </div>
    </div>
    <!-- Mobile sort options overlay end -->
    <v-sheet class="d-flex justify-space-between align-center pc-filters">
      <div> {{ itemsPerPage }} {{ $t('kuvatud') }} {{ totalProducts }}<span v-if="this.$i18n.locale === 'et'">-st</span></div>
      <div class="d-flex justify-end">
        <v-text-field class="flex-0-0 w-50" v-model="search" prepend-inner-icon="mdi-magnify" variant="outlined" :label="$t('otsi')"></v-text-field>
        <v-select class="flex-0-0 px-5 w-66" v-model="sortOption" :items="sortOptions" item-title="text" :label="$t('sorteeri')" menu-icon="mdi-chevron-down" variant="outlined"></v-select>
        <v-select class="flex-0-0 w-33" v-model="perPage" :items="perPageOptions" :label="$t('tooteid')" menu-icon="mdi-chevron-down" variant="outlined"></v-select>
      </div>
    </v-sheet>
    <div class="d-flex">
      <div class="sidebar text-left pr-4">
        <h3>{{ $t('filter') }}</h3>
        <v-list role="listbox" aria-label="Product List">
          <v-list-item role="option" active-color="eraklient" class="py-1 filter-items" v-for="category in getCategories" :key="category.key" :to="`/tooted/${category.key.toLowerCase()}`" draggable="false" :title="category.value"></v-list-item>
          <v-list-item role="option" v-if="$route.path.startsWith('/tooted/')" @click="this.$router.push('/tooted')"  base-color="#e10000">{{$t('eemalda_filter')}}</v-list-item>
        </v-list>
        <v-btn v-if="isLoggedIn" color="success" to="/tooted/addeditcategory">lisa/muuda</v-btn>
      </div>
      <div>
        <v-divider class="content-divider" vertical></v-divider>
      </div>

      <div class="w-100">
        <div class="product mb-5">
          <v-card flat hover class="prod-item pa-4" v-for="product in paginatedPosts" :key="product.id" :href="'/tooted/' + getLinkCat(product) + '/' + product.id" draggable="false">
            <div class="product-link">
              <a class="edit-link mx-auto" v-if="isLoggedIn" :href="'/tooted/' + getLinkCat(product) + '/editproduct/' + product.id">
                <i class="fa fa-cog pa-2"></i>
              </a>
              <div class="product-img">
                <img v-if="product.image" :src="product.image" draggable="false" alt="Toote pilt"/>
                <img width="1812" height="767" class="d-flex align-center justify-center default-logo" v-else src="../../assets/images/lohnakas.webp" alt="Lohnakas logo"/>
              </div>
              <v-card-item class="my-5 pa-0 text-left">
                <v-card-title class="font-weight-bold"> <span class="head"> {{ getHeader(product) }} </span> </v-card-title>
                <v-card-subtitle>
                  <div class="prices">
                    <p class="new-price pr-2" v-if="product.new_price"> {{ product.new_price }}€</p>
                    <p class="old-price" v-if="product.new_price"> {{ product.price }}€</p>
                    <p v-if="!product.new_price"> {{ product.price }}€</p>
                  </div>
                </v-card-subtitle>
                <v-card-text class="pa-0">
                  <span class="cat"> {{ getCat(product) }}</span>
                </v-card-text>
              </v-card-item>
            </div>
            <add-to-cart :product="product"></add-to-cart>
          </v-card>
        </div>
        <v-pagination v-model="page" :length="totalPages"></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {toast} from "vue3-toastify";
import Alert from "@/components/Alert";
import AddToCart from "@/components/AddToCart";

export default {
  name: "ProductView",
  components: {AddToCart, Alert, PageHeader},
  props: ['category'],
  data() {
    return {
      dialog: false,
      products: [],
      categories: [],
      cat: [],
      selectedCategory: sessionStorage.getItem('selectedCategory'),
      sortOption: sessionStorage.getItem('sortOption') || 'default',
      quantity: 1,
      page: Number(sessionStorage.getItem('currentPageProducts')) || 1,
      perPage: Number(sessionStorage.getItem('perPage')) || 10,
      perPageOptions: [10, 16, 20],
      search: sessionStorage.getItem('search') || ''
    };
  },
  computed: {
    cartItemCount() {
      return this.$store.getters.cartItemCount;
    },
    cart() {
      return this.$store.state.cart;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    getCategories() {
      let categories;
      if (this.$i18n.locale === 'en') {
        categories = this.categories.map(category => ({ key: category.name, value: category.name_en ? category.name_en : category.name }));
      }
      else if (this.$i18n.locale === 'ru') {
        categories = this.categories.map(category => ({ key: category.name, value: category.name_ru ? category.name_ru : category.name }));
      }
      else {
        categories = this.categories.map(category => ({ key: category.name, value: category.name }));
      }
      return categories;
    },
    paginatedPosts() {
      const start = (this.page - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredProducts.slice(start, end);
    },
    filteredProducts() {
      let products;
      let category;
      if (this.categories) category = this.categories.find(cat => cat.name.toLowerCase() === this.category);
      if (this.category && category) {
        products = this.products.filter(product => product.category_id === category.id);
      }
      else {
        products = this.products;
      }
      // Filter products based on search term
      products = products.filter(product =>
          (product.header && product.header.toLowerCase().includes(this.search)) ||
          (product.header && product.header.includes(this.search)) ||
          (product.header_en && product.header_en.toLowerCase().includes(this.search)) ||
          (product.header_en && product.header_en.includes(this.search)) ||
          (product.header_ru && product.header_ru.toLowerCase().includes(this.search)) ||
          (product.header_ru && product.header_ru.includes(this.search))
      );
      if (this.sortOption === 'price-asc') {
        products.sort((a, b) => (a.new_price || a.price) - (b.new_price || b.price));
      } else if (this.sortOption === 'price-desc') {
        products.sort((a, b) => (b.new_price || b.price) - (a.new_price || a.price));
      }
      return products;
    },
    totalPages() {
      return Math.ceil(this.filteredProducts.length / this.perPage);
    },
    totalProducts() {
      return this.products.length;
    },
    itemsPerPage() {
      if (this.products.length < this.perPage)
        return this.products.length;
      else return this.perPage;
    },
    sortOptions() {
      return [
        { text: this.$t('uuemad'), value: 'default' },
        { text: this.$t('odavamad'), value: 'price-asc' },
        { text: this.$t('kallimad'), value: 'price-desc' }
      ]
    }
  },
  methods: {
    fetchProducts() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/products`)
          .then((response) => response.json())
          .then((data) => {
            this.originalProducts = data.reverse();
            this.products = [...this.originalProducts];
          })
          .catch((err) => console.log(err.message));
    },
    fetchCats() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/categories`)
          .then((response) => response.json())
          .then((data) => {
            this.categories = data;
          })
          .catch((err) => console.log(err.message));
    },
    increaseQuantity() {
      this.quantity++;
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    addToCart(product) {
      this.$store.dispatch('addToCart', { product: product, quantity: this.quantity });
      this.quantity = 1;
    },
    showToastSuccess(value) {
      toast.success(value, {
        autoClose: 3000
      })
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getCat(content) {
      let id = content.category_id;
      let category = this.categories.find(cat => cat.id === id);
      if (category) return (this.$i18n.locale === 'en' && category.name_en !== null) ? category.name_en : (this.$i18n.locale === 'ru' && category.name_ru !== null) ? category.name_ru : category.name;
    },
    getLinkCat(content) {
      return this.$store.getters.getLinkCat(content);
    }
  },
  mounted() {
    this.fetchProducts();
    this.fetchCats();
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      this.$store.commit('loadCart', JSON.parse(savedCart));
    }
  },
  watch: {
    page(newPage) {
      sessionStorage.setItem('currentPageProducts', newPage);
    },
    perPage(newPageNr) {
      sessionStorage.setItem('perPage', newPageNr);
      this.dialog = false;
    },
    sortOption(newSortOption) {
      sessionStorage.setItem('sortOption', newSortOption);
      this.dialog = false;
    },
    selectedCategory(newValue) {
      let newCategory = this.categories.find(category => category.name === newValue)?.name;
      if (newCategory === undefined) {
        location.assign("/tooted");
        sessionStorage.removeItem('selectedCategory');
      }
      else {
        location.assign(`/tooted/${newCategory.toLowerCase()}`);
        sessionStorage.setItem('selectedCategory', newCategory);
      }
      this.dialog = false;
    },
    search(newSearchOption) {
      sessionStorage.setItem('search', newSearchOption);
    },
  },
  created() {
    this.currentCategory = sessionStorage.getItem('currentCategory') || 'Uuemad tooted';
    this.$store.dispatch('fetchProducts');
    this.$store.dispatch('fetchCategories');
  },
  beforeUnmount() {
    sessionStorage.removeItem('selectedCategory');
  },
}
</script>


<style scoped>
.product-img img {
  height: 160px;
  width: auto;
  object-fit: cover;
}

img {
  margin: auto;
}

.product-img .default-logo {
  object-fit: scale-down;
}

.filter-items:hover {
  color: #3b71f7;
}

.prod-item img {
  width: 100%;
  display: block;
}

.product {
  display: flex;
  flex-wrap: wrap;
}

.prod-item {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.head {
  white-space: normal;
  font-size: 20px;
}

.mobile-filters {
  display: none;
}

.product-filters a:hover {
  color: #3b71f7;
}

.prices {
  display: inline-flex;
  flex-direction: row;
}

.product-link {
  flex-grow: 1;
}

@media (max-width: 1200px) {
  .prod-item {
    width: 33.3%;
  }
  .product-img img {
    height: 200px;
  }
}

@media (max-width: 1000px) {
  .sidebar {
    display: none;
  }
  .mobile-filters {
    display: flex;
  }
  .pc-filters {
    display: none !important;
  }
  .content-divider {
    display: none;
  }
}

@media (max-width: 820px) {
  .prod-item {
    width: 50%;
  }
  .product-img img {
    height: 250px;
  }
}

@media (max-width: 599px) {
  .prod-item {
    width: 100%;
  }
  .product-img img {
    height: auto;
    width: 100%;
  }
}
</style>