<template>
  <page-header class="px-10">
    <h1>Muuda üritust</h1>
  </page-header>
  <v-sheet class="d-flex flex-column mx-auto pa-10">
    <v-form ref="form">
      <v-text-field
          v-model=post.image
          label="Pilt"
          variant="outlined"
      ></v-text-field>
      <v-tabs v-model="tab" bg-color="eraklient">
        <v-tab value="one">EST</v-tab>
        <v-tab value="two">ENG</v-tab>
        <v-tab value="three">RUS</v-tab>
      </v-tabs>
      <v-window class="py-5" v-model="tab">
        <v-window-item value="one">
          <v-text-field
              v-model=post.title
              label="Pealkiri"
              :rules="rules"
              required
              variant="outlined"
          ></v-text-field>
          <tiptap v-model="post.body"/>
        </v-window-item>
        <v-window-item value="two">
          <v-text-field
              v-model=post.title_en
              label="Pealkiri(en)"
              required
              variant="outlined"
          ></v-text-field>
          <tiptap v-model="post.body_en"/>
        </v-window-item>
        <v-window-item value="three">
          <v-text-field
              v-model=post.title_ru
              label="Pealkiri(ru)"
              required
              variant="outlined"
          ></v-text-field>
          <tiptap v-model="post.body_ru"/>
        </v-window-item>
      </v-window>
      <v-btn color="success" @click="updateService" block class="mt-2">Uuenda</v-btn>
      <alert>
        <template #first-btn>Kustuta</template>
        <div class="text-h5 justify-center text-center">Kas oled kindel, et soovid postituse kustutada?</div>
        <template #confirmation-btn>
          <v-btn class="text-none mr-4 font-weight-bold" color="eraklient" min-width="92" rounded @click.prevent="deleteService">Jah</v-btn>
        </template>
      </alert>
    </v-form>
  </v-sheet>
</template>

<script>
import Tiptap from "@/components/Tiptap";
import Alert from "@/components/Alert";
import PageHeader from "@/components/PageHeader";
export default {
  name: "EditMeeskond",
  components: {PageHeader, Alert, Tiptap},
  data() {
    return {
      valid: false,
      tab: null,
      post: [],
      rules: [
        value => {
          if (value) return true
          return 'Ei saa olla tühi.'
        },
      ]
    }
  },
  methods: {
    fetchAnService(id) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/services/meeskonnad/${id}`)
          .then((response) => response.json())
          .then((data) => {
            this.post = data;
          })
          .catch((err) => console.log(err.message));
    },
    async updateService () {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        fetch(`${process.env.VUE_APP_SERVER_URL}/api/services/meeskonnad/${this.post.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.post),
        })
            .then((response) => {
              console.log(response.data);
              location.assign("/teenused/meeskonnakoolitused");
            })
            .catch((e) => {
              console.log(e);
            });
      }
    },
    deleteService() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/services/meeskonnad/${this.post.id}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      })
          .then((response) => {
            console.log(response.data);
            location.assign("/teenused/meeskonnakoolitused");
          })
          .catch((e) => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.fetchAnService(this.$route.params.id);
  }
}
</script>

<style scoped>

</style>