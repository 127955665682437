<!-- Vaheleht "Uudised" -->
<template>
  <div class="page-properties">
    <PageHeader>
      <h1>{{ $t('uudised') }}</h1>
      <v-btn v-if="isLoggedIn" color="success" to="uudised/addpost">lisa +</v-btn>
    </PageHeader>
    <div class="search-bar">
      <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" variant="outlined" label="Otsi postitusi"></v-text-field>
    </div>
    <v-sheet>
      <v-list>
        <v-list-item class="post py-10" v-for="post in paginatedPosts" :key="post.id" :href="'/uudised/' + post.id" :aria-label="getHeader(post)">
          <a v-if="isLoggedIn" :href="'/uudised/editpost/' + post.id">
            <i class="fa fa-cog"></i>
          </a>
          <div class="d-flex post-content" aria-label="Post list">
            <img v-if="post.image" :src="post.image" alt=""/>
            <img v-else class="default-logo" src="../../assets/images/lohnakas.webp" alt="Lohnakas logo"/>
            <div class="post-text">
              <v-list-item-subtitle>{{ this.getDate(post.date) }}</v-list-item-subtitle>
              <h2 class="py-6">{{ getHeader(post) }}</h2>
              <div class="post-body" v-html=getBody(post)></div>
            </div>
          </div>
        </v-list-item>
      </v-list>
      <v-pagination v-model="page" :length="totalPages"></v-pagination>
    </v-sheet>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapGetters } from 'vuex';

export default {
  name: "NewsView",
  components: {PageHeader},
  data() {
    return {
      posts: [],
      page: Number(sessionStorage.getItem('currentPage')) || 1,
      perPage: 5,
      search: '',
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    filteredPosts() {
      return this.posts.filter(post =>
          (post.header && post.header.toLowerCase().includes(this.search)) ||
          (post.header && post.header.includes(this.search)) ||
          (post.header_en && post.header_en.toLowerCase().includes(this.search)) ||
          (post.header_en && post.header_en.includes(this.search)) ||
          (post.header_ru && post.header_ru.toLowerCase().includes(this.search)) ||
          (post.header_ru && post.header_ru.includes(this.search))
      );
    },
    paginatedPosts() {
      const start = (this.page - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredPosts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredPosts.length / this.perPage);
    },
    ...mapGetters([
      'formatDate'
    ]),
  },
  watch: {
    page(newPage) {
      sessionStorage.setItem('currentPage', newPage);
      window.scrollTo(0, 0);
    }
  },
  methods: {
    fetchPosts() {
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/posts/`)
          .then((response) => response.json())
          .then((data) => {
            this.posts = data;
          })
          .catch((err) => {
            console.log(err.message);
          });
    },
    getDate(date) {
      return this.formatDate(date);
    },
    getHeader(content) {
      return (this.$i18n.locale === 'en' && content.header_en !== null) ? content.header_en : (this.$i18n.locale === 'ru' && content.header_ru !== null) ? content.header_ru : content.header;
    },
    getBody(content) {
      return (this.$i18n.locale === 'en' && content.body_en !== null) ? content.body_en : (this.$i18n.locale === 'ru' && content.body_ru !== null) ? content.body_ru : content.body;
    }
  },
  mounted() {
    this.fetchPosts();
    console.log("mounted");
  }
};

</script>


<style scoped>
.post-body {
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 1.5em;
}

.post-body p {
  display: inline;
}

img {
  width: 300px;
  height: auto;
  object-fit: cover;
  margin-right: 1em;
}

img.default-logo {
  width: 300px;
  height: auto;
  object-fit: scale-down;
}

.post-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 10px;
  overflow: hidden;
  justify-content: center;
}

.v-list-item-title {
  white-space: break-spaces;
  line-height: 1em;
}

@media (max-width: 820px) {
  .post {
    padding: 20px !important;
  }
  .post-content {
    flex-direction: column;
  }
  .post-text {
    padding: 0;
  }
  img {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
  }
  img.default-logo {
    width: 100%;
  }
  .search-bar {
    padding: 0 20px;
  }
}

</style>