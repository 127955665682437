<!-- Kustutamise hoiatuse komponent -->
<template>
  <div class="text-center">
    <v-btn class="mt-2" color="error" block @click="dialog = true"><slot name="first-btn"></slot></v-btn>
    <v-dialog v-model="dialog" width="auto" transition="none">
      <v-card>
        <v-toolbar color="white" title="Kinnitus">
          <v-btn icon="mdi-close" @click="dialog = false"></v-btn>
        </v-toolbar>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-divider></v-divider>
        <div class="ma-4">
          <slot name="confirmation-btn"></slot>
          <v-btn class="text-none font-weight-bold" min-width="92" rounded @click="dialog = false">Ei</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Alert",
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    deletePost() {
      this.$emit('delete-post');
    },
  }
}

</script>

<style scoped>

</style>